<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import Loader from "@/components/loader/Loader.vue";
import CheckIbanBank from "@/components/check-iban/CheckIbanBank.vue";
import { computed, onMounted } from "vue";
import ResultCard from "../card/ResultCard.vue";
import { toLower } from "lodash";

const props = withDefaults(defineProps<{
  checkIban: Object,
  loader: boolean,
  birthdate: string,
  messageError: boolean,
  canCheckIban: boolean
}>(), {
  loader: false,
  birthdate: '',
  messageError: false,
  canCheckIban: false,
});

const bankDetails = computed(() => {
  if(props.checkIban?.bank && props.checkIban?.bank.hasOwnProperty('result')) {
    return Object.assign({}, props.checkIban?.bank, props.checkIban?.bank?.result)
  } else return props.checkIban?.bank
});

const displayNameMatching = (detail: {
  check: string;
  message: string;
  status: string;
}) => {
  if (detail.check === "NAME_MATCH") {
    const percentage = detail.message.split(" ")?.reverse()[0];
    return `${translate(
      `CHECK_IBAN.RESULT.${detail.status}.${detail.check}`
    )} (match ${percentage})`;
  } else return translate(`CHECK_IBAN.RESULT.${detail.status}.${detail.check}`);
}

const displayRecommended = computed(() => {
  switch(props.checkIban?.analysisStatus) {
    case 'OK':
      return toLower('GOOD');
    case 'KO':
      return toLower('RISKY');
    case 'WARNING':
      return toLower('AVERAGE');  
  }
});
</script>
<template>
  <ContentCard v-if="!canCheckIban" :checked-display-content="false" :is-padlock="true" class="applicant-b2c"
               :title="`${translate('CHECK_IBAN.RESULT.TITLE')}`"/>
  <template v-else>
    <ContentCard :title="`${translate('CHECK_IBAN.RESULT.TITLE')}`" class="check-iban-result">
      <CheckIbanBank :bank-name="bankDetails?.name" :iban="bankDetails?.iban" :bic="bankDetails?.bic"/>
      <Loader v-if="loader"/>
      <div v-if="!loader" class="check-iban-result__verifications">
        <template v-if="!messageError">
          <ResultCard class="verifications" :title="translate('CHECK_IBAN.MEELO_RECOMMENDATION')">
            <div class="verifications__global">
              <span
                  :class="`verifications__global__decision verifications__global__decision__${displayRecommended}`">{{
                  translate(`CHECK_IBAN.RECOMMENDATION.DECISION.${displayRecommended}`)
                }}</span>
              <span :class="`verifications__global__message__${displayRecommended}`">{{
                  translate(`CHECK_IBAN.RECOMMENDATION.MESSAGE.${displayRecommended}`)
                }}</span>
            </div>
          </ResultCard>
          <ResultCard :title="translate('CHECK_IBAN.RESULT.VERIFICATION')">
            <div
                  v-for="(detail, index) in props.checkIban?.checks"
                  :key="index"
                >
                  <span>
                    <img
                      :src="
                        require(`/public/images/${
                          detail.status === 'OK'
                            ? 'success-icon.png'
                            : detail.status === 'WARNING'
                            ? 'warning-icon.png'
                            : 'failed-icon.png'
                        }`)
                      "
                      :alt="`${detail.status.toLowerCase()}-icon`"
                      class="check-iban-result__icon"
                    />
                    {{  displayNameMatching(detail) }}              
                  </span>
                </div>          
          </ResultCard>
        </template>
        <template v-else>
          <span>{{ translate('CHECK_IBAN.ERROR_MESSAGE') }}</span>
        </template>
      </div>
    </ContentCard>
  </template>
</template>