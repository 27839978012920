/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
    /*if (!isLocalhost) {
        document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
    } else {
        document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; Secure; HttpOnly; path=/";
    }*/
}

export function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
        let part = parts.pop();
        if(part != undefined)
        {
            let splitted = part.split(";");
            if(splitted != undefined)
                return splitted.shift() as string;
            else
                return undefined;
        }
    }
}

export function deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+"=; expires="+date.toUTCString()+"; Secure; HttpOnly; path=/";
}

export default {setCookie, getCookie, deleteCookie};