import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31409b2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-b2b" }
const _hoisted_2 = { class: "container-b2b__analysis" }
const _hoisted_3 = { class: "print-b2c-aml-cft-container" }
const _hoisted_4 = { class: "print-b2b-company-finance-container" }
const _hoisted_5 = { class: "print-b2b-annual-account-container" }
const _hoisted_6 = { class: "print-b2b-establishments-container" }
const _hoisted_7 = { class: "print-b2b-company-evolution-container" }
const _hoisted_8 = { class: "print-b2b-company-evolution-container" }
const _hoisted_9 = { class: "print-b2c-check-iban-container" }
const _hoisted_10 = { class: "ob-financial-analysis-period" }
const _hoisted_11 = { class: "print-ob-analysis-detail-container" }
const _hoisted_12 = { class: "print-ob-analysis-alert-container" }
const _hoisted_13 = { class: "to-print" }
const _hoisted_14 = { class: "no-print" }
const _hoisted_15 = { class: "container-b2b__analysis-selections" }
const _hoisted_16 = {
  key: 2,
  class: "container-b2b__analysis-result"
}
const _hoisted_17 = { class: "container-b2b__analysis-result-title" }
const _hoisted_18 = {
  key: 11,
  class: "container-b2b__analysis-result"
}
const _hoisted_19 = { class: "container-b2b__analysis-result-title" }
const _hoisted_20 = { class: "container-b2b__analysis-selections" }
const _hoisted_21 = { class: "container-b2b__analysis-selections" }
const _hoisted_22 = {
  key: 0,
  class: "openbanking-bloc-link"
}
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "no-print" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrintFolderInfo = _resolveComponent("PrintFolderInfo")!
  const _component_PrintInfoWithLabel = _resolveComponent("PrintInfoWithLabel")!
  const _component_OutputWarningList = _resolveComponent("OutputWarningList")!
  const _component_PrintCard = _resolveComponent("PrintCard")!
  const _component_PrintB2BBusinessOwner = _resolveComponent("PrintB2BBusinessOwner")!
  const _component_PrintB2BBusinessRepresent = _resolveComponent("PrintB2BBusinessRepresent")!
  const _component_PrintApplicantOB = _resolveComponent("PrintApplicantOB")!
  const _component_BoxOutputScoreSMB = _resolveComponent("BoxOutputScoreSMB")!
  const _component_BoxOutputScoreCompany = _resolveComponent("BoxOutputScoreCompany")!
  const _component_BoxOutputCompanyLitigation = _resolveComponent("BoxOutputCompanyLitigation")!
  const _component_BoxOutputAmlcft = _resolveComponent("BoxOutputAmlcft")!
  const _component_BoxOutputCompanyFinances = _resolveComponent("BoxOutputCompanyFinances")!
  const _component_BoxOutputCompanyLegalDoc = _resolveComponent("BoxOutputCompanyLegalDoc")!
  const _component_BoxOutputAnnualAccounts = _resolveComponent("BoxOutputAnnualAccounts")!
  const _component_BoxOutputEstablishments = _resolveComponent("BoxOutputEstablishments")!
  const _component_BoxOutputBodaccAnnouncements = _resolveComponent("BoxOutputBodaccAnnouncements")!
  const _component_BoxOutputEReputation = _resolveComponent("BoxOutputEReputation")!
  const _component_BoxOutputCheckIbanB2b = _resolveComponent("BoxOutputCheckIbanB2b")!
  const _component_CheckIbanBox = _resolveComponent("CheckIbanBox")!
  const _component_BoxOutputOpenBankingDetail = _resolveComponent("BoxOutputOpenBankingDetail")!
  const _component_BoxOutputOpenBankingAlert = _resolveComponent("BoxOutputOpenBankingAlert")!
  const _component_BoxOutputDocument = _resolveComponent("BoxOutputDocument")!
  const _component_BoxInputStateFolder = _resolveComponent("BoxInputStateFolder")!
  const _component_EditFolderModal = _resolveComponent("EditFolderModal")!
  const _component_DeleteFolderModal = _resolveComponent("DeleteFolderModal")!
  const _component_BoxInputSiren = _resolveComponent("BoxInputSiren")!
  const _component_BoxOutputOwnerRepresentative = _resolveComponent("BoxOutputOwnerRepresentative")!
  const _component_BoxInputPersonalInfo = _resolveComponent("BoxInputPersonalInfo")!
  const _component_BoxOutputCompanyInfo = _resolveComponent("BoxOutputCompanyInfo")!
  const _component_BoxOutputCompanyCartography = _resolveComponent("BoxOutputCompanyCartography")!
  const _component_DocumentAnalysis = _resolveComponent("DocumentAnalysis")!
  const _component_BoxOutputScoreCreditSafe = _resolveComponent("BoxOutputScoreCreditSafe")!
  const _component_BoxInputEnrichment = _resolveComponent("BoxInputEnrichment")!
  const _component_BoxOutputEnrichment = _resolveComponent("BoxOutputEnrichment")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BoxInputOpenBanking = _resolveComponent("BoxInputOpenBanking")!
  const _component_BoxOutputOpenBanking = _resolveComponent("BoxOutputOpenBanking")!
  const _component_BoxOutputOpenBankingBankStatement = _resolveComponent("BoxOutputOpenBankingBankStatement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PrintFolderInfo, {
        entries: {
        uniqId: _ctx.journeyId,
        }
      }, null, 8, ["entries"]),
      _createVNode(_component_PrintInfoWithLabel, {
        title: "PRINT.ANALYSIS_B2B.COMPANY_INFO",
        entries: _ctx.computeCompanyInfoEntries(_ctx.checkCompanyLegalUnitData, _ctx.causesB2BDataV)
      }, null, 8, ["entries"]),
      (((_ctx.scoreB2BDataV && _ctx.scoreB2BDataV.length > 0) || _ctx.isScoreB2BLoading)  && _ctx.permissionsSorted?.includes('KIT_B2B_WARNING_LIST_GLOBAL'))
        ? (_openBlock(), _createBlock(_component_PrintCard, {
            key: 0,
            title: "PRINT.ANALYSIS_B2B.WARNING_LIST"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OutputWarningList, {
                "warning-list": _ctx.causesB2BDataV?.warningList
              }, null, 8, ["warning-list"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_PrintB2BBusinessOwner, {
        title: "PRINT.ANALYSIS_B2B.BUSINESS_OWNERS_KNOWN",
        entries: _ctx.checkCompanyShareHoldersData
      }, null, 8, ["entries"]),
      _createVNode(_component_PrintB2BBusinessRepresent, {
        title: "PRINT.ANALYSIS_B2B.BUSINESS_REPRESENTS_KNOWN",
        entries: _ctx.checkCompanyRepresentativesData
      }, null, 8, ["entries"]),
      _createVNode(_component_PrintApplicantOB, {
        title: "PRINT.ANALYSIS_B2B.CLIENT_INFORMATION",
        color: _ctx.colorSMB,
        score: Math.round(_ctx.scoreSMBData),
        entries: _ctx.computeClientInfoEntries(_ctx.prefillClientForm)
      }, null, 8, ["color", "score", "entries"]),
      (((_ctx.isScoreCompany || _ctx.checkIbanData || _ctx.amlCftMatchesData) && !_ctx.scoreSMBBlock) || ((_ctx.isScoreSmb|| _ctx.isScoreSMBLoading) && _ctx.scoreSMBBlock))
        ? (_openBlock(), _createBlock(_component_PrintCard, {
            key: 1,
            title: "PRINT.ANALYSIS_B2B.SCORE_DETAILS"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BoxOutputScoreSMB, {
                scoreSMB: _ctx.scoreSMBData,
                "search-image": _ctx.searchImageProfilesData,
                causesSMB: _ctx.causesSMBData,
                applicant: _ctx.applicantData,
                company: _ctx.user?.company?.name,
                color: _ctx.colorSMB,
                permissions: _ctx.permissionsSorted,
                "can-score-s-m-b": _ctx.scoreSMBBlock
              }, null, 8, ["scoreSMB", "search-image", "causesSMB", "applicant", "company", "color", "permissions", "can-score-s-m-b"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (((_ctx.isScoreSmb || _ctx.checkIbanData || _ctx.amlCftMatchesData) && !_ctx.companyScoreBlock) || ((_ctx.isScoreCompany || _ctx.isScoreB2BLoading) && _ctx.companyScoreBlock))
        ? (_openBlock(), _createBlock(_component_PrintCard, {
            key: 2,
            title: "PRINT.ANALYSIS_B2B.COMPANY_SCORE_DETAILS"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BoxOutputScoreCompany, {
                scoringB2B: _ctx.scoreB2BDataV,
                causesB2B: _ctx.causesB2BDataV,
                company: _ctx.user?.company?.name,
                "journey-id": _ctx.journeyId,
                color: _ctx.colorB2B,
                permissions: _ctx.permissionsSorted,
                "permissions-company-alert": _ctx.permissionsCompanyAlert,
                "can-company-score": _ctx.companyScoreBlock
              }, null, 8, ["scoringB2B", "causesB2B", "company", "journey-id", "color", "permissions", "permissions-company-alert", "can-company-score"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputCompanyLitigation)
        ? (_openBlock(), _createBlock(_component_PrintCard, {
            key: 3,
            title: ""
          }, {
            default: _withCtx(() => [
              (_ctx.canShowBoxOutputCompanyLitigation)
                ? (_openBlock(), _createBlock(_component_BoxOutputCompanyLitigation, {
                    key: 0,
                    data: _ctx.causesB2BData,
                    "is-loader": !_ctx.causesB2BData,
                    "can-check-litigation": _ctx.companyLitigationBlock
                  }, null, 8, ["data", "is-loader", "can-check-litigation"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.AMLCFT" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (((_ctx.causesB2BDataV || _ctx.causesSMBData || _ctx.checkIbanData) && !_ctx.amlcftBlock) || (_ctx.amlCftMatchesData && _ctx.amlcftBlock))
              ? (_openBlock(), _createBlock(_component_BoxOutputAmlcft, {
                  key: 0,
                  amlcft: _ctx.amlCftMatchesData ? _ctx.amlCftMatchesData[0] : [],
                  "show-content": _ctx.amlCftMatchesData,
                  "can-amlcft": _ctx.amlcftBlock
                }, null, 8, ["amlcft", "show-content", "can-amlcft"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.COMPANY_FINANCE" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.checkCompanyCountryData !== 'ES' && ((_ctx.checkCompanyLegalUnitData && !_ctx.companyFinancesBlock) || (_ctx.taxReportsSummaryData && _ctx.companyFinancesBlock)))
              ? (_openBlock(), _createBlock(_component_BoxOutputCompanyFinances, {
                  key: 0,
                  "tax-reports": _ctx.filteredTaxReportsSummaryData,
                  loader: _ctx.taxReportLoading,
                  "can-company-finances": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_FINANCES_GLOBAL')
                }, null, 8, ["tax-reports", "loader", "can-company-finances"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      ((_ctx.checkCompanyLegalUnitData && !_ctx.companyLegalDocBlock) || (_ctx.checkCompanyAttachmentsData && _ctx.companyLegalDocBlock))
        ? (_openBlock(), _createBlock(_component_PrintCard, {
            key: 4,
            title: "PRINT.ANALYSIS_B2B.COMPANY_LEGAL_DOC"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BoxOutputCompanyLegalDoc, {
                "to-print": true,
                "legal-doc": _ctx.checkCompanyAttachmentsData.legalActs,
                "registration-number": _ctx.checkCompanyLegalUnitData.registrationNumber,
                "journey-id": _ctx.journeyId,
                "can-company-legal-doc": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_LEGAL_DOC_GLOBAL')
              }, null, 8, ["legal-doc", "registration-number", "journey-id", "can-company-legal-doc"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.ANNUAL_ACCOUNTS" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            ((_ctx.checkCompanyLegalUnitData && !_ctx.annualAccountsBlock) || (_ctx.checkCompanyAttachmentsData && _ctx.annualAccountsBlock))
              ? (_openBlock(), _createBlock(_component_BoxOutputAnnualAccounts, {
                  key: 0,
                  "tax-reports": _ctx.checkCompanyAttachmentsData.taxReports,
                  "journey-id": _ctx.journeyId,
                  "can-annual-accounts": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_ANNUAL_ACCOUNT_GLOBAL')
                }, null, 8, ["tax-reports", "journey-id", "can-annual-accounts"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.ESTABLISHMENTS" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            ((_ctx.checkCompanyLegalUnitData && !_ctx.establishmentsBlock) || (_ctx.checkCompanyEstablishmentsData && _ctx.establishmentsBlock))
              ? (_openBlock(), _createBlock(_component_BoxOutputEstablishments, {
                  key: 0,
                  establishments: _ctx.checkCompanyEstablishmentsData,
                  "can-establishments": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_ESTABLISHMENTS_GLOBAL')
                }, null, 8, ["establishments", "can-establishments"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.COMPANY_EVOLUTION" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            ((_ctx.checkCompanyLegalUnitData && !_ctx.bodaccAnnouncementsBlock) || _ctx.bodaccAnnouncementsBlock)
              ? (_openBlock(), _createBlock(_component_BoxOutputBodaccAnnouncements, {
                  key: 0,
                  announcements: _ctx.checkCompanyLegalObservationData,
                  "can-bodacc-announcements": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_EVOLUTION_GLOBAL')
                }, null, 8, ["announcements", "can-bodacc-announcements"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.SCORING" }),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.E_REPUTATION" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            ((_ctx.checkCompanyLegalUnitData && !_ctx.eReputationBlock) || (_ctx.causesEReputationData && _ctx.scoreEReputationData && _ctx.eReputationBlock))
              ? (_openBlock(), _createBlock(_component_BoxOutputEReputation, {
                  key: 0,
                  "e-reputation": _ctx.causesEReputationData,
                  "show-e-reputation-content": _ctx.isEReputationLoading || (_ctx.causesEReputationData && _ctx.scoreEReputationData),
                  "show-e-reputation-loader": _ctx.isEReputationLoading,
                  "scoring-e-reputation": _ctx.scoreEReputationData,
                  color: _ctx.colorEReputation,
                  permissions: _ctx.permissionsSorted,
                  "can-e-reputation": _ctx.eReputationBlock
                }, null, 8, ["e-reputation", "show-e-reputation-content", "show-e-reputation-loader", "scoring-e-reputation", "color", "permissions", "can-e-reputation"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.IBAN" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.isCheckIbanV1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (((_ctx.causesB2BDataV || _ctx.causesSMBData || _ctx.amlCftMatchesData) && !_ctx.checkIbanBlock) || ((_ctx.showCheckIbanLoader || _ctx.messageCheckIban || _ctx.checkIbanData) && _ctx.checkIbanBlock))
                    ? (_openBlock(), _createBlock(_component_BoxOutputCheckIbanB2b, {
                        key: 0,
                        "check-iban": _ctx.checkIbanData,
                        loader: _ctx.showCheckIbanLoader,
                        "can-check-iban": _ctx.checkIbanBlock
                      }, null, 8, ["check-iban", "loader", "can-check-iban"]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.displayCheckIbanBox)
                    ? (_openBlock(), _createBlock(_component_CheckIbanBox, {
                        key: 0,
                        "check-iban": _ctx.checkIbanData,
                        loader: _ctx.showCheckIbanLoader,
                        "can-check-iban": _ctx.checkIbanBlock
                      }, null, 8, ["check-iban", "loader", "can-check-iban"]))
                    : _createCommentVNode("", true)
                ], 64))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_PrintApplicantOB, {
        entries: {
          bankName: _ctx.obFormattedFields.bankName,
          holder: _ctx.obFormattedFields.holderName,
          account: _ctx.obFormattedFields.accountName,
          iban: _ctx.obFormattedFields.iban,
          mainAccount: _ctx.obFormattedFields.topSecondaryAccount,
          monthSalaries: _ctx.obFormattedFields.monthSalaries,
          monthRents: _ctx.obFormattedFields.monthRents,
          monthEndRatio: _ctx.obFormattedFields.monthEndRatio,
          balance: _ctx.obFormattedFields.balance,
          topBankingSupervision: _ctx.obFormattedFields.topBankingSupervision,
          topDeferredDebit: _ctx.obFormattedFields.topDeferredDebit,
          monthEndBalance: _ctx.obFormattedFields.monthEndBalance,
          globalTransactionsCount: _ctx.obFormattedFields.globalTransactionsCount,
        },
        title: _ctx.translate('PRINT.APPLICANT_OB.OB_TITLE'),
        score: Math.round(_ctx.obFormattedFields.score || 0),
        color: _ctx.colorScoreOB
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_10, _toDisplayString(`${_ctx.translate("OPEN_BANKING.FINANCIAL_ANALYSIS_PERIOD")}: ${
                _ctx.obFormattedFields.startStudyPeriod || " -/- "
            } ${_ctx.translate("OPEN_BANKING.TO")} ${
                _ctx.obFormattedFields.endStudyPeriod || " -/- "
            } `), 1)
        ]),
        _: 1
      }, 8, ["entries", "title", "score", "color"]),
      _createVNode(_component_PrintCard, { title: "PRINT.OB_ANALYSIS_DETAIL" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            ((Object.keys(_ctx.obFormattedFields).length > 0) && _ctx.openBankingDetailBlock)
              ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingDetail, {
                  key: 0,
                  "ob-data": _ctx.obFormattedFields,
                  permissions: _ctx.permissionsSorted,
                  "can-open-banking": _ctx.permissionsOpenBankingDetails[0].length > 0
                }, null, 8, ["ob-data", "permissions", "can-open-banking"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_PrintCard, { title: "PRINT.ANALYSIS_B2B.ALERTS" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            ((Object.keys(_ctx.obFormattedFields).length > 0) && _ctx.openBankingAlertBlock)
              ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingAlert, {
                  key: 0,
                  "ob-data": _ctx.obFormattedFields,
                  "can-open-banking": _ctx.permissionsSorted.includes('KIT_B2B_OB_ALERTS_GLOBAL')
                }, null, 8, ["ob-data", "can-open-banking"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_13, [
        (((_ctx.isScoreSmb || _ctx.isScoreCompany) && !_ctx.restitutionDocumentBlock) || (_ctx.checkCompanyLegalUnitData  && _ctx.restitutionDocumentBlock))
          ? (_openBlock(), _createBlock(_component_BoxOutputDocument, {
              key: 0,
              showFolderInfoOnPrint: false,
              isLoading: _ctx.isLoading,
              "context-data": _ctx.contextData,
              "can-restitution-document": _ctx.restitutionDocumentBlock
            }, null, 8, ["isLoading", "context-data", "can-restitution-document"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.displayStateFolder)
          ? (_openBlock(), _createBlock(_component_BoxInputStateFolder, {
              key: 0,
              "case-number": _ctx.businessId,
              "case-state": _ctx.journeyIdStatus,
              onDeleteFolder: _ctx.openModalDeleteFolder,
              states: _ctx.states(),
              "journey-id": _ctx.journeyId,
              onSaveFolder: _ctx.openModalEditFolder,
              motive: _ctx.motive
            }, null, 8, ["case-number", "case-state", "onDeleteFolder", "states", "journey-id", "onSaveFolder", "motive"]))
          : _createCommentVNode("", true),
        _createVNode(_component_EditFolderModal, {
          "open-modal": _ctx.modalEditFolder,
          "onUpdate:openModal": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalEditFolder) = $event)),
          "case-number": _ctx.businessId,
          "case-state": _ctx.journeyIdStatus,
          onConfirmSelection: _ctx.editFolder,
          onCloseModal: _ctx.undoFolder
        }, null, 8, ["open-modal", "case-number", "case-state", "onConfirmSelection", "onCloseModal"]),
        _createVNode(_component_DeleteFolderModal, {
          "open-modal": _ctx.modalDeleteFolder,
          "onUpdate:openModal": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalDeleteFolder) = $event)),
          onConfirmSelection: _ctx.deleteFolder,
          "case-number": _ctx.businessId
        }, null, 8, ["open-modal", "onConfirmSelection", "case-number"]),
        _createVNode(_component_BoxInputSiren, {
          onCompanySearch: _ctx.companySearch,
          "show-button": _ctx.showInputSirenButton,
          "siren-warning-list": _ctx.warningListData,
          "show-siren": _ctx.checkCompanyRegistrationNumberData,
          permissions: _ctx.permissionsSorted,
          default: _ctx.$route.query.registrationNumber?.toString(),
          "is-duplicate": _ctx.isDuplicateFolder(),
          "company-country": _ctx.checkCompanyCountryData,
          "is-loading": _ctx.warningListLoading
        }, null, 8, ["onCompanySearch", "show-button", "siren-warning-list", "show-siren", "permissions", "default", "is-duplicate", "company-country", "is-loading"]),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.canShowBoxOutputBusinessOwner)
            ? (_openBlock(), _createBlock(_component_BoxOutputOwnerRepresentative, {
                key: 0,
                "owner-representatives": _ctx.businnessOwnerTransformer(_ctx.checkCompanyCountryData, _ctx.checkCompanyShareHoldersData),
                onPersonDataEmitted: _ctx.askToPrefillClientForm,
                clearPerson: _ctx.clearBusinessOwner,
                disableButtons: _ctx.disablePersonSelection,
                "permission-to-select": _ctx.permissionsSorted.includes('KIT_B2B_BUSINESS_OWNERS_SELECTION'),
                "can-person": _ctx.businessOwnersBlock,
                title: _ctx.translate('COMPANY.BUSINESS_OWNERS_KNOWN'),
                country: _ctx.checkCompanyCountryData,
                type: 'businessOwner'
              }, null, 8, ["owner-representatives", "onPersonDataEmitted", "clearPerson", "disableButtons", "permission-to-select", "can-person", "title", "country"]))
            : _createCommentVNode("", true),
          (_ctx.canShowBoxOutputRepresentative)
            ? (_openBlock(), _createBlock(_component_BoxOutputOwnerRepresentative, {
                key: 1,
                "owner-representatives": _ctx.representativeTransformer(_ctx.checkCompanyCountryData, _ctx.checkCompanyRepresentativesData),
                onPersonDataEmitted: _ctx.askToPrefillClientForm,
                clearPerson: _ctx.clearRepresentative,
                disableButtons: _ctx.disablePersonSelection,
                "permission-to-select": _ctx.permissionsSorted.includes('KIT_B2B_REPRESENTATIVES_SELECTION'),
                "can-person": _ctx.representativesBlock,
                title: _ctx.translate('COMPANY.REPRESENTATIVES_KNOWN'),
                type: 'representative',
                country: _ctx.checkCompanyCountryData
              }, null, 8, ["owner-representatives", "onPersonDataEmitted", "clearPerson", "disableButtons", "permission-to-select", "can-person", "title", "country"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.canShowBoxInputPersonalInfo)
          ? (_openBlock(), _createBlock(_component_BoxInputPersonalInfo, {
              key: 1,
              onShowScore: _ctx.computeScores,
              onCheckAmlcft: _ctx.checkAmlcft,
              onCheckIban: _ctx.checkIbanMethod,
              "market-type": "b2b",
              propPrefillClientForm: _ctx.prefillClientForm,
              disableFraudScoreButton: _ctx.isDisableFraudScoreButton,
              disableIbanButton: _ctx.isDisableIbanButton,
              disableAmlcftButton: _ctx.isDisableAmlcftButton,
              permissions: _ctx.permissionsSorted,
              "double-button": _ctx.companyParameters?.displayScoreFraudCompanyAndSMB,
              "disable-smb-score-button": _ctx.disableSmbScoreButton || (_ctx.scoreSMBData && _ctx.scoreSMBData.length > 0),
              "disable-score-fraud-company-button": _ctx.disableScoreFraudCompanyButton || (_ctx.scoreB2BDataV && _ctx.scoreB2BDataV.length > 0),
              "amlcft-block": _ctx.amlcftBlock,
              "company-score-block": _ctx.companyScoreBlock,
              "score-smb-block": _ctx.scoreSMBBlock,
              "check-iban-block": _ctx.checkIbanBlock,
              "can-web-score-b2c": _ctx.canWebScoreB2c,
              "company-country": _ctx.checkCompanyCountryData
            }, null, 8, ["onShowScore", "onCheckAmlcft", "onCheckIban", "propPrefillClientForm", "disableFraudScoreButton", "disableIbanButton", "disableAmlcftButton", "permissions", "double-button", "disable-smb-score-button", "disable-score-fraud-company-button", "amlcft-block", "company-score-block", "score-smb-block", "check-iban-block", "can-web-score-b2c", "company-country"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxInputPersonalInfo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.translate("RESULT")), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputCompanyInfo && !_ctx.isString(_ctx.responseStatus.companyCheckErrorMessage))
          ? (_openBlock(), _createBlock(_component_BoxOutputCompanyInfo, {
              key: 3,
              "show-information-company-content": _ctx.checkCompanyLegalUnitData,
              "check-company": _ctx.companyInfoTransformer(_ctx.checkCompanyCountryData, { ..._ctx.checkCompanyLegalUnitData, companyCountry: _ctx.checkCompanyCountryData }),
              "company-causes": _ctx.causesB2BDataV?.company,
              permissions: _ctx.permissionsSorted,
              "can-company-info": _ctx.companyInfoBlock
            }, null, 8, ["show-information-company-content", "check-company", "company-causes", "permissions", "can-company-info"]))
          : _createCommentVNode("", true),
        (_ctx.canShowOutputWarningList)
          ? (_openBlock(), _createBlock(_component_OutputWarningList, {
              key: 4,
              "warning-list": _ctx.getWarningListData,
              "is-loading": _ctx.isScoreB2BLoading || _ctx.isScoreSMBLoading
            }, null, 8, ["warning-list", "is-loading"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputScoreSMB)
          ? (_openBlock(), _createBlock(_component_BoxOutputScoreSMB, {
              key: 5,
              scoreSMB: _ctx.scoreSMBData,
              "search-image": _ctx.searchImageProfilesData,
              causesSMB: _ctx.causesSMBData,
              applicant: _ctx.applicantData,
              company: _ctx.user?.company?.name,
              color: _ctx.colorSMB,
              permissions: _ctx.permissionsSorted,
              "can-score-s-m-b": _ctx.scoreSMBBlock,
              "display-multi-requests": !_ctx.causesB2BDataV
            }, null, 8, ["scoreSMB", "search-image", "causesSMB", "applicant", "company", "color", "permissions", "can-score-s-m-b", "display-multi-requests"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputScoreCompany)
          ? (_openBlock(), _createBlock(_component_BoxOutputScoreCompany, {
              key: 6,
              scoringB2B: _ctx.scoreB2BDataV,
              causesB2B: _ctx.causesB2BDataV,
              company: _ctx.user?.company?.name,
              "journey-id": _ctx.journeyId,
              color: _ctx.colorB2B,
              permissions: _ctx.permissionsSorted,
              "permissions-company-alert": _ctx.permissionsCompanyAlert,
              "can-company-score": _ctx.companyScoreBlock
            }, null, 8, ["scoringB2B", "causesB2B", "company", "journey-id", "color", "permissions", "permissions-company-alert", "can-company-score"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputCompanyLitigation)
          ? (_openBlock(), _createBlock(_component_BoxOutputCompanyLitigation, {
              key: 7,
              data: _ctx.causesB2BDataV,
              "is-loader": !_ctx.causesB2BDataV,
              "can-check-litigation": _ctx.companyLitigationBlock
            }, null, 8, ["data", "is-loader", "can-check-litigation"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputCompanyCartography)
          ? (_openBlock(), _createBlock(_component_BoxOutputCompanyCartography, {
              dataChart: _ctx.causesB2BDataV?.graph,
              onSirenChart: _ctx.goOpenNewTabFromChart,
              "company-country": _ctx.checkCompanyCountryData,
              "can-cartography": _ctx.cartographyBlock,
              key: _ctx.causesB2BDataV?.graph
            }, null, 8, ["dataChart", "onSirenChart", "company-country", "can-cartography"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputCheckIbanB2b)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
              (_ctx.isCheckIbanV1)
                ? (_openBlock(), _createBlock(_component_BoxOutputCheckIbanB2b, {
                    key: 0,
                    "check-iban": _ctx.checkIbanData,
                    loader: _ctx.showCheckIbanLoader,
                    "can-check-iban": _ctx.checkIbanBlock
                  }, null, 8, ["check-iban", "loader", "can-check-iban"]))
                : (_openBlock(), _createBlock(_component_CheckIbanBox, {
                    key: 1,
                    "check-iban": _ctx.checkIbanData,
                    loader: _ctx.showCheckIbanLoader,
                    "can-check-iban": _ctx.checkIbanBlock
                  }, null, 8, ["check-iban", "loader", "can-check-iban"]))
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputAmlcft)
          ? (_openBlock(), _createBlock(_component_BoxOutputAmlcft, {
              key: 10,
              amlcft: _ctx.amlCftMatchesData ? _ctx.amlCftMatchesData[0] : [],
              "show-content": _ctx.amlCftMatchesData,
              "can-amlcft": _ctx.amlcftBlock
            }, null, 8, ["amlcft", "show-content", "can-amlcft"]))
          : _createCommentVNode("", true),
        (_ctx.checkCompanyLegalUnitData && _ctx.isDataAvalaible(_ctx.checkCompanyLegalUnitData) && !_ctx.isDuplicateFolder())
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.translate('ADDITIONAL_CONTROLS')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.canShowDocumentAnalysis)
          ? (_openBlock(), _createBlock(_component_DocumentAnalysis, {
              key: 12,
              "show-title": false,
              siren: _ctx.checkCompanyRegistrationNumberData,
              onLoadTaxReportResult: _ctx.reloadTaxReportResult
            }, null, 8, ["siren", "onLoadTaxReportResult"]))
          : _createCommentVNode("", true),
        (_ctx.checkCompanyCountryData !== 'ES' && _ctx.canShowBoxOutputCompanyFinances)
          ? (_openBlock(), _createBlock(_component_BoxOutputCompanyFinances, {
              key: 13,
              "tax-reports": _ctx.filteredTaxReportsSummaryData,
              loader: _ctx.taxReportLoading,
              "can-company-finances": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_FINANCES_GLOBAL')
            }, null, 8, ["tax-reports", "loader", "can-company-finances"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_20, [
          (_ctx.canShowBoxOutputCompanyLegalDoc)
            ? (_openBlock(), _createBlock(_component_BoxOutputCompanyLegalDoc, {
                key: 0,
                "legal-doc": _ctx.checkCompanyAttachmentsData.legalActs,
                "registration-number": _ctx.checkCompanyLegalUnitData.registrationNumber,
                "journey-id": _ctx.journeyId,
                "can-company-legal-doc": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_LEGAL_DOC_GLOBAL'),
                country: _ctx.checkCompanyCountryData
              }, null, 8, ["legal-doc", "registration-number", "journey-id", "can-company-legal-doc", "country"]))
            : _createCommentVNode("", true),
          (_ctx.canShowBoxOutputAnnualAccounts)
            ? (_openBlock(), _createBlock(_component_BoxOutputAnnualAccounts, {
                key: 1,
                country: _ctx.checkCompanyCountryData,
                "tax-reports": _ctx.checkCompanyAttachmentsData.taxReports,
                "journey-id": _ctx.journeyId,
                "can-annual-accounts": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_ANNUAL_ACCOUNT_GLOBAL')
              }, null, 8, ["country", "tax-reports", "journey-id", "can-annual-accounts"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_21, [
          (_ctx.canShowBoxOutputEstablishments)
            ? (_openBlock(), _createBlock(_component_BoxOutputEstablishments, {
                key: 0,
                establishments: _ctx.establishmentTransformer(_ctx.checkCompanyCountryData, _ctx.checkCompanyEstablishmentsData),
                "company-country": _ctx.checkCompanyCountryData,
                "can-establishments": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_ESTABLISHMENTS_GLOBAL')
              }, null, 8, ["establishments", "company-country", "can-establishments"]))
            : _createCommentVNode("", true),
          (_ctx.canShowBoxOutputBodaccAnnouncements)
            ? (_openBlock(), _createBlock(_component_BoxOutputBodaccAnnouncements, {
                key: 1,
                announcements: _ctx.bodaccAnnouncementTransformer(_ctx.checkCompanyCountryData, _ctx.checkCompanyLegalObservationData),
                "can-bodacc-announcements": _ctx.permissionsSorted.includes('KIT_B2B_COMPANY_EVOLUTION_GLOBAL')
              }, null, 8, ["announcements", "can-bodacc-announcements"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.canShowBoxOutputScoreCreditSafe)
          ? (_openBlock(), _createBlock(_component_BoxOutputScoreCreditSafe, {
              key: 14,
              "causes-credit-safe": _ctx.causesCreditSafeData,
              "is-company-f-r": _ctx.isCountryFR,
              "is-data-loading": _ctx.isScoreCreditSafeLoading,
              "is-error": _ctx.displayCreditSafeError,
              "score-credit-safe": _ctx.scoreCreditSafeData,
              "color-credit-safe-loc": _ctx.colorCreditSafeLoc,
              "color-credit-safe-inter": _ctx.colorCreditSafeInter,
              "can-credit-safe": _ctx.permissionsSorted.includes('KIT_B2B_CREDIT_SAFE_GLOBAL'),
              onComputeCreditSafeScore: _ctx.computeCreditSafeScore
            }, null, 8, ["causes-credit-safe", "is-company-f-r", "is-data-loading", "is-error", "score-credit-safe", "color-credit-safe-loc", "color-credit-safe-inter", "can-credit-safe", "onComputeCreditSafeScore"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxInputEnrichment)
          ? (_openBlock(), _createBlock(_component_BoxInputEnrichment, {
              key: 15,
              onCheckLinkedin: _ctx.getEnrichment,
              "disabled-linkedin-button": _ctx.disabledEnrichmentButton,
              "can-enrichment": _ctx.permissionsSorted.includes('KIT_B2B_ENRICHMENT_GLOBAL')
            }, null, 8, ["onCheckLinkedin", "disabled-linkedin-button", "can-enrichment"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputEnrichment)
          ? (_openBlock(), _createBlock(_component_BoxOutputEnrichment, {
              key: 16,
              enrichment: _ctx.enrichmentData,
              loader: _ctx.enrichmentLoader,
              "client-form": _ctx.prefillClientForm,
              "can-enrichment": _ctx.permissionsSorted.includes('KIT_B2B_ENRICHMENT_GLOBAL')
            }, null, 8, ["enrichment", "loader", "client-form", "can-enrichment"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputEReputation)
          ? (_openBlock(), _createBlock(_component_BoxOutputEReputation, {
              key: 17,
              "e-reputation-data": _ctx.causesEReputationData,
              "show-e-reputation-loader": _ctx.isEReputationLoading,
              "scoring-e-reputation": _ctx.scoreEReputationData,
              color: _ctx.colorEReputation,
              permissions: _ctx.permissionsSorted,
              "can-e-reputation": _ctx.eReputationBlock
            }, null, 8, ["e-reputation-data", "show-e-reputation-loader", "scoring-e-reputation", "color", "permissions", "can-e-reputation"]))
          : _createCommentVNode("", true),
        (!(Object.keys(_ctx.obFormattedFields).length > 0))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 18 }, [
              (_ctx.canShowBoxInputOpenBanking)
                ? (_openBlock(), _createBlock(_component_BoxInputOpenBanking, {
                    key: 0,
                    onGenerateLink: _ctx.generateLink,
                    "prop-prefill-client-form": {},
                    "company-country": _ctx.checkCompanyCountryData,
                    permissions: _ctx.permissionsSorted,
                    "can-open-baking": _ctx.openBankingInputPermissions
                  }, {
                    default: _withCtx(() => [
                      (_ctx.openBankingLink != '')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.obStarted && !_ctx.obEnded)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                  _createElementVNode("a", {
                                    href: _ctx.openBankingLink,
                                    target: "_blank"
                                  }, [
                                    _createVNode(_component_Button, {
                                      class: "openbanking-bloc-link-button",
                                      onClick: _ctx.goOpenBanking,
                                      label: _ctx.translate('BUTTONS.JOURNEY_OPEN_BANKING')
                                    }, null, 8, ["onClick", "label"])
                                  ], 8, _hoisted_23)
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["onGenerateLink", "company-country", "permissions", "can-open-baking"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputOpenBanking)
          ? (_openBlock(), _createBlock(_component_BoxOutputOpenBanking, {
              key: 19,
              "ob-data": _ctx.obFormattedFields,
              "color-score": _ctx.colorScoreOB,
              "can-open-banking": _ctx.permissionsSorted.includes('KIT_B2B_OB_RESUME_GLOBAL'),
              company: _ctx.user?.company?.name
            }, null, 8, ["ob-data", "color-score", "can-open-banking", "company"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputOpenBankingDetail)
          ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingDetail, {
              key: 20,
              onToAccountSummaryEvent: _ctx.handleAccountSummary,
              "ob-data": _ctx.obFormattedFields,
              permissions: _ctx.permissionsSorted,
              "can-open-banking": _ctx.permissionsSorted.includes('KIT_B2B_OB_DETAILS_GLOBAL'),
              company: _ctx.user?.company?.name
            }, null, 8, ["onToAccountSummaryEvent", "ob-data", "permissions", "can-open-banking", "company"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputOpenBankingAlert)
          ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingAlert, {
              key: 21,
              "ob-data": _ctx.obFormattedFields,
              "can-open-banking": _ctx.permissionsSorted.includes('KIT_B2B_OB_ALERTS_GLOBAL')
            }, null, 8, ["ob-data", "can-open-banking"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_24, [
          (_ctx.isAccountSummaryButtonClicked)
            ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingBankStatement, {
                key: 0,
                "ob-data": _ctx.obFormattedFields
              }, null, 8, ["ob-data"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}