<template>
  <div class="container-b2c">
    <PrintFolderInfo
        :entries="{
        uniqId: journeyId,
      }"
    />
    <PrintApplicantOB
        :entries="{
        lastName: prefillClientForm.lastName.value,
        firstName: prefillClientForm.firstNames.value,
        birthDate: prefillClientForm.birthDate.value,
        email: prefillClientForm.email.value,
        phone: prefillClientForm.phone.value,
        address: `${prefillClientForm.street.value} ${prefillClientForm.city.value} ${prefillClientForm.zipcode.value} ${prefillClientForm.country.value}`,
        iban: prefillClientForm.iban.value
      }"
        :score="Math.round(scoreFraudData)"
        :color="colorB2C"
    />
    <PrintCard
        v-if="(isScoreFraud && !scoringDetailedBoxBlock) || ((causesFraudData != null || isScoreFraudLoading) && scoringDetailedBoxBlock)"
        title="PRINT.SCORE_DETAILS"
    >
      <div class="print-b2c-score-detail-container">
        <ScoringDetailedBox
            :search-image="searchImageProfilesData" :customer-profile="prefillClientForm"
            :causes-fraud="causesFraudData"
            :company="user?.company?.name"
            :permissions="permissionsSorted" :can-scoring="scoringDetailedBoxBlock"
        />
      </div>
    </PrintCard>
    <PrintCard
        v-if="(showCheckIbanLoader || messageCheckIban || checkIbanData) && checkIbanBlock"
        title="PRINT.CHECK_IBAN" class="pagebreak"
    >
      <div class="print-b2c-check-iban-container">
        <CheckIbanResult
            v-if="isCheckIbanV1"
            :check-iban="checkIbanData"
            :loader="showCheckIbanLoader"
            :birthdate="birthdate"
            :message-error="messageCheckIban"
            :can-check-iban="checkIbanBlock"
        />
        <CheckIbanBox
            v-else
            :check-iban="checkIbanData"
            :loader="showCheckIbanLoader"
            :birthdate="birthdate"
            :message-error="messageCheckIban"
            :can-check-iban="checkIbanBlock"
        />
      </div>
    </PrintCard>
    <PrintCard
        v-if="amlCftMatchesData && amlcftBlock" :amlcft="amlCftMatchesData[0]"
        title="PRINT.AMLCFT"
    >
      <div class="print-b2c-aml-cft-container">
        <BoxOutputAmlcft :show-content="amlCftMatchesData" :can-amlcft="amlcftBlock"
                         :amlcft="amlCftMatchesData ? amlCftMatchesData[0] : []"/>
      </div>
    </PrintCard>
    <div class="container-b2c__analysis no-print">
      <BoxInputStateFolder v-if="displayStateFolder" :case-number="businessId" :case-state="journeyIdStatus"
                           @delete-folder="openModalDeleteFolder" :states="states()" :motive="motive"
                           @save-folder="openModalEditFolder"/>
      <EditFolderModal v-model:open-modal="modalEditFolder" :case-number="businessId" :case-state="journeyIdStatus"
                       @confirm-selection="editFolder"/>
      <DeleteFolderModal v-model:open-modal="modalDeleteFolder" @confirm-selection="deleteFolder"
                         :case-number="businessId"/>
      <div class="container-b2c__analysis-block">
        <BoxInputPersonalInfo @show-score="computeScoreFraud" @check-amlcft="checkAmlcft"
                              @check-iban="checkIbanMethod"
                              market-type="b2c" :propPrefillClientForm="prefillClientForm"
                              :disableFraudScoreButton="isDisableFraudScoreButton"
                              :disableIbanButton="isDisableIbanButton"
                              :disableAmlcftButton="isDisableAmlcftButton"
                              :permissions="permissionsSorted"
                              :permissions-company-alert="permissionsCompanyAlert"
                              :amlcft-block="amlcftBlock"
                              :company-score-block="companyScoreBlock"
                              :score-smb-block="scoreSMBBlock"
                              :check-iban-block="checkIbanBlock"
                              :can-web-score-b2c="canWebScoreB2c"
        />
        <div v-if="canShowApplicant">
          <Applicant v-if="canShowApplicant" :score="scoreFraudData" :color="colorB2C"
                     :permissions="permissionsSorted"/>
          <BoxOutputWarningList v-if="canShowBoxOutputWarningList" :warning-list="causesFraudData?.warning_list"
                                :is-loading="isNull(causesFraudData)"/>
        </div>
        <BoxOutputAmlcft v-if="canShowBoxOutputAmlcft" :amlcft="amlCftMatchesData ? amlCftMatchesData[0] : []"
                         :show-content="amlCftMatchesData"
                         :can-amlcft="amlcftBlock"/>
        <template v-if="canShowCheckIbanResult">
          <CheckIbanResult v-if="isCheckIbanV1" :check-iban="checkIbanData" :loader="showCheckIbanLoader"
                           :birthdate="contextData?.applicant?.birthDate" :message-error="messageCheckIban"
                           :can-check-iban="checkIbanBlock"/>
          <CheckIbanBox v-else :check-iban="checkIbanData" :loader="showCheckIbanLoader"
                        :birthdate="contextData?.applicant?.birthDate" :message-error="messageCheckIban"
                        :can-check-iban="checkIbanBlock"/>
        </template>
      </div>
      <ScoringDetailedBox v-if="canShowScoringDetailedBox" :search-image="searchImageProfilesData"
                          :customer-profile="prefillClientForm" :causes-fraud="causesFraudData"
                          :company="user?.company?.name" :permissions="permissionsSorted"
                          :can-scoring="scoringDetailedBoxBlock" @dqe-control="getDqeControl"
                          :rule-engine-decision="ruleEngineDecisionForDqeControl" :dqe-control-result="dqeControlData"
                          :dqe-loader="isDqeControlLoading"/>
      <DocumentAnalysis v-if="canShowDocumentAnalysis" :show-title="false"/>
      <div class="container-b2c__analysis-block">
        <BoxInputEnrichment v-if="canShowBoxInputEnrichment" @check-linkedin="getEnrichment"
                            :disabled-linkedin-button="disabledEnrichmentButton"
                            :can-enrichment="permissionsSorted.includes('KIT_B2C_ENRICHMENT_GLOBAL')"/>
        <BoxOutputEnrichment v-if="canShowBoxOutputEnrichment" :client-form="prefillClientForm"
                             :enrichment="enrichmentData" :loader="enrichmentLoader"
                             :can-enrichment="permissionsSorted.includes('KIT_B2C_ENRICHMENT_GLOBAL')"
                             :display-match="displayEnrichmentMatch"/>
      </div>
      <BoxInputCheckEmployer
          v-if="canShowBoxInputCheckEmployer" :loader="isCheckEmployer"
          @check-employer="checkEmployer"
          :can-check-employer="permissionsSorted.includes('KIT_B2C_CHECK_EMPLOYER_GLOBAL')"/>
      <BoxOutputCheckEmployer
          v-if="canShowBoxOutputCheckEmployer" :check-employer-data="checkCompanyLegalUnitData"
          :check-warning-list="warningListData" :check-procedure="checkEmployerCollectiveProceedingsData"
          :no-data-available="noDataAvailable" :is-loading="warningListLoading"
      />
      <template v-if="!(Object.keys(obFormattedFields).length > 0)">
        <BoxInputOpenBanking v-if="canShowBoxInputOpenBanking" @generate-link="generateLink"
                             :prop-prefill-client-form="{}"
                             :permissions="permissionsSorted" :can-open-baking="openBankingInputPermissions">
          <template v-if="openBankingLink != ''">
            <div class="openbanking-bloc-link" v-if="obStarted && !obEnded">
              <a :href="openBankingLink" target="_blank">
                <Button class="openbanking-bloc-link-button" @click="goOpenBanking"
                        :label="translate('BUTTONS.JOURNEY_OPEN_BANKING')"/>
              </a>
            </div>
          </template>
        </BoxInputOpenBanking>
      </template>
      <BoxOutputOpenBanking
          v-if="canShowBoxOutputOpenBanking"
          :ob-data="obFormattedFields" :color-score="colorScoreOB"
          :can-open-banking="permissionsSorted.includes('KIT_B2C_OB_RESUME_GLOBAL')" :company="user?.company?.name"/>
      <BoxOutputOpenBankingDetail
          v-if="canShowBoxOutputOpenBankingDetail"
          @to-account-summary-event="handleAccountSummary"
          :ob-data="obFormattedFields"
          :permissions="permissionsSorted"
          :can-open-banking="permissionsSorted.includes('KIT_B2C_OB_DETAILS_GLOBAL')"
          :company="user?.company?.name"/>
      <BoxOutputOpenBankingAlert
          v-if="canShowBoxOutputOpenBankingAlert"
          :ob-data="obFormattedFields"
          :can-open-banking="permissionsSorted.includes('KIT_B2C_OB_ALERTS_GLOBAL')"/>
      <div class="no-print">
        <BoxOutputOpenBankingBankStatement v-if="isAccountSummaryButtonClicked" :ob-data="obFormattedFields"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Applicant from "@/components/blocks/Applicant.vue";
import {CustomerProfile, CustomerProfileV3} from "@/types/CustomerProfile";
import {getCustomerFraudDataV3} from "@/apiCalls/scoringApi";
import ScoringDetailedBox from "@/components/scoring-detailed-box/ScoringDetailedBox.vue";
import Button from "@/components/button/Button.vue";
import {searchAmlcft} from "@/apiCalls/amlcftApi";
import {checkIbanNaturalPerson, getBankName} from "@/apiCalls/checkIban";
import CheckIbanResult from "@/components/check-iban/CheckIbanResult.vue";
import BoxOutputAmlcft from "@/components/box-output/BoxOutputAmlcft.vue";
import BoxInputDocument from "@/components/box-input/BoxInputDocument.vue";
import BoxOutputDocument from "@/components/box-output/BoxOutputDocument.vue";
import PrintFolderInfo from "@/components/print/shared/PrintFolderInfo.vue";
import PrintApplicantOB from "@/components/print/shared/PrintApplicantOB.vue";
import PrintCard from "@/components/print/shared/PrintCard.vue";
import templateMixin from "@/mixins/templateMixin";
import BoxInputPersonalInfo from "@/components/box-input/BoxInputPersonalInfo.vue";
import utilsMixin from "@/mixins/utilsMixin";
import {translate} from "@/i18n";
import {mapGetters} from "vuex";
import BoxOutputWarningList from "@/components/box-output/BoxOutputWarningList.vue";
import {sendApplicantInformation} from "@/apiCalls/taskApi";
import BoxInputEnrichment from "@/components/box-input/BoxInputEnrichment.vue";
import BoxOutputEnrichment from "@/components/box-output/BoxOutputEnrichment.vue";
import DocumentAnalysis from "@/views/DocumentAnalysis.vue";
import {isEmpty, isEqual, isNull, isObject, padStart} from "lodash";
import {searchImage} from "@/apiCalls/faceMatchingApi";
import BoxInputCheckEmployer from "@/components/box-input/BoxInputCheckEmployer.vue";
import BoxOutputCheckEmployer from "@/components/box-output/BoxOutputCheckEmployer.vue";
import {companyDetails} from "@/apiCalls/companyCheck";
import {searchCountryCode} from "@/utils/countryCodes";
import EditFolderModal from "@/components/modal/EditFolderModal.vue";
import BoxInputStateFolder from "@/components/box-input/BoxInputStateFolder.vue";
import DeleteFolderModal from "@/components/modal/DeleteFolderModal.vue";
import {states} from "@/utils/statesFolder";
import BoxInputOpenBanking from "@/components/box-input/BoxInputOpenBanking.vue";
import BoxOutputOpenBankingBankStatement from "@/components/box-output/BoxOutputOpenBankingBankStatement.vue";
import BoxOutputOpenBankingDetail from "@/components/box-output/BoxOutputOpenBankingDetail.vue";
import BoxOutputOpenBankingAlert from "@/components/box-output/BoxOutputOpenBankingAlert.vue";
import BoxOutputOpenBanking from "@/components/box-output/BoxOutputOpenBanking.vue";
import OpenBankingMixin from "@/mixins/openBankingMixin";
import CheckIbanBox from "@/components/check-iban/CheckIbanBox.vue";
import {getIdMobile} from "@/apiCalls/enrichmentApi";
import {setRule} from "@/apiCalls/rulesEngine";

export default defineComponent({
  name: "AnalysisB2C",
  mixins: [templateMixin, utilsMixin, OpenBankingMixin],
  components: {
    BoxOutputOpenBanking,
    BoxOutputOpenBankingAlert,
    BoxOutputOpenBankingDetail,
    BoxOutputOpenBankingBankStatement,
    BoxInputOpenBanking,
    DeleteFolderModal, BoxInputStateFolder, EditFolderModal,
    BoxOutputCheckEmployer,
    BoxInputCheckEmployer,
    BoxOutputWarningList,
    BoxInputPersonalInfo,
    BoxOutputAmlcft,
    CheckIbanResult,
    CheckIbanBox,
    Button,
    ScoringDetailedBox,
    Applicant,
    BoxInputDocument,
    BoxOutputDocument,
    PrintFolderInfo,
    PrintApplicantOB,
    PrintCard,
    BoxInputEnrichment,
    BoxOutputEnrichment,
    DocumentAnalysis
  },
  data: () => ({
    isUploadDocument: false,
    customerProfile: {} as CustomerProfile,
    showResultOCR: false,
    timer: "" as any,
    allDocumentType: [] as string[],
    allDocumentTypeValue: [] as string[],
    showCheckIbanLoader: false,
    prefillClientForm: {
      lastName: {disabled: false, value: ""},
      firstNames: {disabled: false, value: ""},
      phone: {disabled: false, value: "+33"},
      email: {disabled: false, value: ""},
      companyName: {disabled: false, value: ""},
      country: {disabled: false, value: ""},
      city: {disabled: false, value: ""},
      zipcode: {disabled: false, value: ""},
      street: {disabled: false, value: ""},
      birthDate: {disabled: false, value: ""},
      iban: {disabled: false, value: ""},
    } as any,
    disableFraudScoreButton: false,
    disableIbanButton: false,
    disableAmlcftButton: false,
    isScoreFraudLoading: false,
    messageCheckIban: false,
    ibanResponse: '',
    isCheckEmployer: false,
    noDataAvailable: false,
    warningListLoading: false,
    colorScoreOB: "",
    isCheckIbanV1: false,
    isDqeControlLoading: false,
    customerProfileDqeControl: {} as CustomerProfileV3,
    errorDqeControl: false,
    ruleEngineDecisionForDqeControl: false
  }),
  async created() {
    window.addEventListener("resetAnalysisB2c", this.cleanAnalysis);
    this.document;
  },
  beforeUnmount() {
    window.removeEventListener("resetAnalysisB2c", this.cleanAnalysis);
    clearInterval(this.timer);
  },
  watch: {
    applicantData(data: any) {
      if (!data) return;
      const {email, firstname, lastname, addresses, mobilePhone, birthDate, iban}: {
        email?: string;
        mobilePhone?: string;
        firstname?: string;
        lastname?: string;
        birthDate?: string;
        addresses?: { street1: string; city: string; zipcode: string; country: string }[];
        iban?: string;
      } = data;
      const test = (
          attribute: keyof {
            lastName: { disabled: boolean; value: string };
            firstNames: { disabled: boolean; value: string };
            phone: { disabled: boolean; value: string };
            email: { disabled: boolean; value: string };
            companyName: { disabled: boolean; value: string };
            country: { disabled: boolean; value: string };
            city: { disabled: boolean; value: string };
            zipcode: { disabled: boolean; value: string };
            street: { disabled: boolean; value: string };
            birthDate: { disabled: boolean; value: string };
            iban: { disabled: boolean; value: string };
          },
          value?: string
      ) => {
        if (value) {
          this.prefillClientForm[attribute].value = value;
          this.prefillClientForm[attribute].disabled = true;
          return;
        }

        this.prefillClientForm[attribute].disabled = false;
      };

      test("email", email);
      test("phone", mobilePhone);
      test("firstNames", firstname);
      test("lastName", lastname);
      test("birthDate", birthDate);
      test("iban", iban);

      if (!isEmpty(this.ibanResponse)) {
        this.prefillClientForm['iban'].value = this.ibanResponse;
        this.prefillClientForm['iban'].disabled = true;
      }

      const address = addresses ? addresses[0] : {street1: "", city: "", zipcode: "", country: ""};
      this.prefillClientForm.street.value = address?.street1;
      this.prefillClientForm.street.disabled = !!address?.street1;
      this.prefillClientForm.city.value = address?.city;
      this.prefillClientForm.city.disabled = !!address?.city;
      this.prefillClientForm.zipcode.value = address?.zipcode;
      this.prefillClientForm.zipcode.disabled = !!address?.zipcode;
      this.prefillClientForm.country.value = address?.country;
      this.prefillClientForm.country.disabled = !!address?.country;

      if (this.isDuplicateFolder()) {
        this.allowModificationTextInput(this.prefillClientForm);
      }
    },
    async 'contextData.check_iban_details'(newValue: any) {
      const iban = this.prefillClientForm.iban.value
      if (iban && newValue.length > 0) {
        if (newValue[0] && newValue[0].checks && newValue[0].checks.length > 0) {
          this.isCheckIbanV1 = newValue[0].checks[0].hasOwnProperty('scope');
        }
        // remove later
        const bank = await getBankName(iban, this.journeyId);
        this.contextData.check_iban_details[0] = Object.assign({}, newValue[0], {
          bank: {
            ...bank.data.result,
            iban
          }
        });
      }
    },
    async obFormattedFields() {
      this.colorScoreOB = await this.defineColor(
          "score_ob",
          this.obFormattedFields.score,
          this.journeyId
      );
    },
    '$route'(val) {
      if (val.query.duplicate) {
        this.allowModificationTextInput(this.prefillClientForm)
        this.disableFraudScoreButton = false
        this.disableIbanButton = false
        this.disableAmlcftButton = false
      }
    }
  },
  methods: {
    isNull,
    states() {
      return states;
    },
    translate,
    async computeScoreFraud(customerProfileV3: CustomerProfileV3) {
      this.isScoreFraudLoading = true;
      this.disableFraudScoreButton = true;

      //For Dqe Control
      this.customerProfileDqeControl = customerProfileV3;

      if (!this.journeyId || this.isDuplicateFolder()) {
        this.contextData = {};
        await this.createJourneyId("portal-b2c");
      }
      const {data: {data: applicantInfo}} = await sendApplicantInformation(customerProfileV3, this.journeyId);

      try {
        customerProfileV3.address.country = searchCountryCode(customerProfileV3.address.country);
        this.contextData.applicant = applicantInfo != null ? [applicantInfo] : [];
        const response = await getCustomerFraudDataV3(customerProfileV3, this.journeyId);
        this.contextData.web_score_b2c = [response.result.score];
        this.colorB2C = await this.defineColor("score_b2c", this.scoreFraudData, this.journeyId);
        this.contextData.causes_web_score_b2c = response?.causes != null ? [response.causes] : [];

        if (isNull(this.causesFraudData.idMobile)) {
          // Rule engine for DQE control
          const rule = await setRule('PORTAL_DQE_ACTIVATION', this.journeyId);
          this.ruleEngineDecisionForDqeControl = rule?.result?.globalDecision;
        }

        this.isScoreFraudLoading = false;
      } catch (response: any) {
        this.isScoreFraudLoading = false;
        this.phoneNotValid(response.response.data);
      }

      //Search Image
      searchImage(customerProfileV3, this.journeyId).then((response: any) => {
        this.contextData.search_image = response !== null ? [response.result.profiles] : [];
      });
    },
    isDuplicateFolder() {
      return this.$route.query.duplicate !== undefined;
    },
    handleEvent() {
      if (!this.showResultOCR) {
        this.showResultOCR = true;
      }
      // Remove the event listener after the event has occurred
      window.removeEventListener("message", this.handleEvent);
    },
    cleanAnalysis() {
      this.isUploadDocument = false;
      this.showCheckIbanLoader = false;
      this.contextData = {};
      this.disableFraudScoreButton = false;
      this.disableAmlcftButton = false;
      this.disableIbanButton = false;
      this.prefillClientForm = {
        lastName: {disabled: false, value: ""},
        firstNames: {disabled: false, value: ""},
        phone: {disabled: false, value: "+33"},
        email: {disabled: false, value: ""},
        country: {disabled: false, value: ""},
        city: {disabled: false, value: ""},
        zipcode: {disabled: false, value: ""},
        street: {disabled: false, value: ""},
        birthDate: {disabled: false, value: ""},
        iban: {disabled: false, value: ""}
      };
      this.isScoreFraudLoading = false;
      this.messageCheckIban = false;
      this.disabledEnrichmentButton = false;
      this.ibanResponse = '';
      this.isCheckEmployer = false;
      this.noDataAvailable = false;
      this.journeyIdStatus = '';
      this.isDqeControlLoading = false;
      this.errorDqeControl = false;
    },
    async checkIbanMethod(iban: string, customerProfileV3: CustomerProfileV3) {
      this.ibanResponse = iban;
      this.disableIbanButton = true;
      this.showCheckIbanLoader = true;
      if (this.journeyId === undefined || this.isDuplicateFolder()) {
        this.contextData = {};
        await this.createJourneyId("portal-b2c");
      }

      const {data: {data: applicantInfo},} = await sendApplicantInformation(customerProfileV3, this.journeyId, iban);
      this.contextData.applicant = applicantInfo != null ? [applicantInfo] : [];
      let timer = setTimeout(() => {
        this.showCheckIbanLoader = false;
        this.messageCheckIban = true;
      }, 120000);

      const checkIbanRequest = {
        lastName: customerProfileV3.identity.lastName,
        firstName: customerProfileV3.identity.firstName,
        birthDate: customerProfileV3.identity.birthDate ? customerProfileV3.identity.birthDate : null,
        country: 'FR',
        iban: iban,
      };

      const bank = await getBankName(iban, this.journeyId);
      const responseIban = await checkIbanNaturalPerson(this.journeyId, checkIbanRequest);

      if (responseIban) clearTimeout(timer);
      this.contextData.check_iban_details = responseIban.data?.result ? [{checks: responseIban.data?.result?.checks, analysisStatus: responseIban.data?.result?.analysisStatus}] : []
      this.showCheckIbanLoader = false;
    },
    async checkAmlcft(customerProfileV3: CustomerProfileV3) {
      this.disableAmlcftButton = true;
      if (this.journeyId === undefined || this.isDuplicateFolder()) {
        this.contextData = {};
        await this.createJourneyId("portal-b2c");
      }
      const {data: {data: applicantInfo},} = await sendApplicantInformation(customerProfileV3, this.journeyId);
      this.contextData.applicant = applicantInfo != null ? [applicantInfo] : [];
      const response = await searchAmlcft(customerProfileV3.identity.lastName, customerProfileV3.identity.firstName, customerProfileV3.identity.birthDate, this.journeyId);
      this.contextData.amlcft_matches = response ? [response.data.result] : [];
    },
    async checkEmployer(siren: string, country: string) {
      this.isCheckEmployer = true;
      this.warningListLoading = true;
      let formattedSiren = siren.replaceAll(" ", "");
      if (isEqual(country, 'BE')) formattedSiren = padStart(formattedSiren, 10, '0');
      const response = await companyDetails(formattedSiren, this.journeyId, country);
      if (response.data.status === "FAILED") {
        // when there a response with failed status, we set the company_legal_unit to NO_COMPANY manually to not show the box input
        this.noDataAvailable = true;
        this.contextData.company_legal_unit = ['NO_COMPANY'];
      } else if (response.data.status === "SUCCESS") {
        this.contextData.company_legal_unit = response?.data?.result != null ? [response.data?.result.company.legalUnit] : [];
        this.contextData.company_registration_number = response?.data?.result != null ? [response.data?.result.company.registrationNumber] : [];
        this.contextData.company_collective_proceedings = response?.data?.result != null ? [response?.data?.result?.company?.collectiveProceedings] : [];
        this.noDataAvailable = false;
      }
      this.isCheckEmployer = false;
      await this.getWarningList(this.journeyId, "MEELO_BLACKLIST", "OR", {SIREN: formattedSiren}, "SIREN");
      this.warningListLoading = false;
    },
    async getDqeControl() {
      this.isDqeControlLoading = true;
      try {
        const response = await getIdMobile(this.journeyId, this.customerProfileDqeControl);
        this.contextData.dqe_control = response?.result ? [response.result] : [];
        this.isDqeControlLoading = false;
      } catch (e) {
        console.error(e);
        this.errorDqeControl = true;
        this.isDqeControlLoading = false;
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'kits', 'permissionsSorted']),
    applicantBlock() {
      return this.permissionsSorted?.includes('KIT_B2C_WEB_SCORE_SCORE');
    },
    isDisableFraudScoreButton() {
      return this.disableFraudScoreButton || (this.isDuplicateFolder() ? false : (this.scoreFraudData !== null));
    },
    isDisableIbanButton() {
      return this.disableIbanButton || (this.isDuplicateFolder() ? false : this.checkIbanData);
    },
    isDisableAmlcftButton() {
      return this.disableAmlcftButton || (this.isDuplicateFolder() ? false : this.amlCftMatchesData);
    },
    canShowApplicant() {
      return this.applicantBlock && (this.isScoreFraud || this.isScoreFraudLoading) && !this.isDuplicateFolder();
    },
    canShowBoxOutputWarningList() {
      return (this.causesFraudData != null && Object.keys(this.causesFraudData.warning_list).some((elt) => elt.includes('phone') ||
              elt.includes('email')) && (this.causesFraudData.warning_list.phone !== 0 || this.causesFraudData.warning_list.email !== 0))
          && this.permissionsSorted?.includes('KIT_B2C_WEB_SCORE_WARNING_LIST') && !this.isDuplicateFolder();
    },
    canShowBoxOutputAmlcft() {
      return this.amlCftMatchesData && this.amlcftBlock && !this.isDuplicateFolder();
    },
    canShowCheckIbanResult() {
      return (this.showCheckIbanLoader || this.messageCheckIban || this.checkIbanData) && this.checkIbanBlock && !this.isDuplicateFolder();
    },
    canShowScoringDetailedBox() {
      return (this.isScoreFraud && !this.scoringDetailedBoxBlock) || ((this.causesFraudData != null || this.isScoreFraudLoading) && this.scoringDetailedBoxBlock) && !this.isDuplicateFolder();
    },
    canShowDocumentAnalysis() {
      return (this.isScoreFraud && !this.uploadDocumentBlock) || ((this.isUploadDocument || this.scoreFraudData != null) && this.uploadDocumentBlock) && !this.isDuplicateFolder();
    },
    canShowBoxInputEnrichment() {
      return this.enrichmentInputBlock && !this.isDuplicateFolder();
    },
    canShowBoxOutputEnrichment() {
      return this.enrichmentOutputBlock && !this.isDuplicateFolder();
    },
    canShowBoxInputDocument() {
      return (this.isScoreFraud && !this.uploadDocumentBlock) || ((this.isUploadDocument || this.scoreFraudData != null) && this.uploadDocumentBlock) && !this.isDuplicateFolder()
    },
    canShowBoxOutputDocument() {
      return (this.isScoreFraud && !this.restitutionDocumentBlock) || ((this.isUploadDocument || this.scoreFraudData != null) && this.restitutionDocumentBlock) && !this.isDuplicateFolder();
    },
    canShowBoxInputCheckEmployer() {
      return this.isScoreFraud && isEmpty(this.contextData.company_legal_unit) && !this.isDuplicateFolder();
    },
    canShowBoxOutputCheckEmployer() {
      return this.isScoreFraud && !isEmpty(this.contextData.company_legal_unit) && !this.isDuplicateFolder();
    },
    document() {
      if (this.permissionsSorted.includes('KIT_B2C_UPLOAD_ID_CHECK')) {
        this.allDocumentType.push(translate("DOCUMENT_CONTROL.ID_CARD"));
        this.allDocumentTypeValue.push('ID_CHECK');
      }

      if (this.permissionsSorted.includes('KIT_B2C_UPLOAD_AUTHENTICITY')) {
        this.allDocumentType.push(translate("DOCUMENT_CONTROL.AUTHENTICITY") + " (" + translate("ONLY_PDF") + ")");
        this.allDocumentTypeValue.push('OTHER');
      }
    },
    canShowBoxInputOpenBanking() {
      return this.scoreFraudData && !this.isDuplicateFolder();
    },
    openBankingInputPermissions() {
      return this.permissionsSorted?.some((elt: string | string[]) => elt.includes('KIT_B2C_OB_INPUT_MAIL') || elt.includes('KIT_B2C_OB_INPUT_TEXT_MESSAGE'));
    },
    displayEnrichmentMatch() {
      return isObject(this.causesFraudData);
    }
  }
});
</script>
