<template>
  <tracked-component>
    <div
        class="App"
        v-if="!isAuthUrl"
        :style="{
        '--sidebar-width': sidebarWidth + 'px',
      }"
    >
      <template v-if="!!kits">
        <Sidebar class="no-print"/>
        <div :class="rightContentClassName">
          <InfoBar class="no-print" :is-maintenance="isMaintenance" :was-maintenance="wasMaintenance"
                   :data="downServices" :key="maintenanceKey"/>
          <HeaderBar :route="$route.path" class="logout no-print"/>
          <RouterView/>
        </div>
      </template>
    </div>
    <!-- LOGIN -->
    <router-view v-else/>
  </tracked-component>
</template>

<script lang="ts">
import {jwtDecode} from "jwt-decode";
import {defineComponent, nextTick} from "vue";
import {RouteRecordName} from "vue-router";
import {mapActions, mapGetters} from "vuex";
import connectAsApi from "./apiCalls/connectAsApi";
import {isNull} from "lodash";

import cookieMixin from "@/mixins/cookieMixin";
import {getTokenFromUrlParams} from "@/utils/getTokenFromUrlParams";

import HeaderBar from "@/components/header/HeaderBar.vue";
import {
  default as MeeloHeader,
  default as Sidebar,
} from "@/components/sidebar/Sidebar.vue";
import TrackedComponent from "@/components/tracking/TrackedComponent.vue";
import InfoBar from "@/components/bar/InfoBar.vue";
import {getMaintenanceAlerts} from "@/apiCalls/alertsApi";
import {possibleServices} from "@/utils/services";

export default defineComponent({
  components: {InfoBar, Sidebar, MeeloHeader, HeaderBar, TrackedComponent},
  mixins: [cookieMixin],
  computed: {
    rightContentClassName(): string {
      return this.$route.path.startsWith("/back-office") && (this.isMaintenance || this.wasMaintenance) ? "right-content right-content-bar backoffice__right-content" :
          this.$route.path.startsWith("/back-office") ? "right-content backoffice__right-content" :
              this.isMaintenance ? "right-content right-content-bar" :
                  this.wasMaintenance ? "right-content right-content-bar" : "right-content";
    },
    routeName(): RouteRecordName | undefined | null {
      return this.$route.name;
    },
    isAuthUrl(): boolean {
      return (
          this.$route.path.startsWith("/login") ||
          this.$route.path.startsWith("/reset-password") ||
          this.$route.path.startsWith("/forgot-password")
      );
    },
    ...mapGetters(["user", "kits", "permissionsSorted", "maintenacesSevices"]),
  },
  data() {
    return {
      sidebarWidth: 250,
      isMaintenance: false,
      wasMaintenance: false,
      downServices: [] as any[],
      downMessages: [] as string[],
      maintenanceTimeout: null as any,
      maintenanceInterval: null as any,
      maintenanceKey: null as any
    };
  },
  methods: {
    ...mapActions(['fetchUser', 'clearResponseStatus', 'setMaintenacesSevices' ]),
    async fetchMaintenanceAlerts() {
      // only fetch maintenance alerts if they match the services.ts
      // ignore the rest of the alerts
      if (["/reset-password", "/forgot-password", "/login"].includes(window.location.pathname)) return
      const res = await getMaintenanceAlerts();
      const validServices = new Set(possibleServices);
      const currentServices = res.data.filter((alert: any) => {
        return alert.type === 'MAINTENANCE'
            && alert.service
            && validServices.has(alert.service)
            // && alert.scope === 'GLOBAL'
      });
      const services = currentServices.map((alert: any) => alert);
      this.downServices = services.filter(service => validServices.has(service.service));
      this.isMaintenance = services.length > 0;
    },
    getTokenFromUrlParams
  },
  async created() {
    document.title = "Portail Meelo";
    const accessToken =
        getTokenFromUrlParams() || this.$cookies.get("access_token");
    const resetToken = getTokenFromUrlParams("reset_token");
    if (!resetToken) {
      if (!accessToken) {
        this.clearUserCookiesAndPushToLogin();
        return;
      }

      const decodedToken = jwtDecode(accessToken);

      if (decodedToken.exp && (decodedToken.exp * 1000) < Date.now()) {
        this.clearUserCookiesAndPushToLogin();
        return;
      }

      await this.fetchUser();

      if (!this.user) {
        this.clearUserCookiesAndPushToLogin();
        return;
      }

      if (this.user) {
        if (this.$route.path.startsWith("/back-office")
            && !["COMPANY_MANAGER", "COMPANY_ADMIN"].some((elt) => this.user.roles.includes(elt))) {
          if (!isNull(this.user.realUser)) await connectAsApi.logoutConnectAs()
          this.clearUserCookiesAndPushToLogin();
        }
        this.maintenanceInterval = setInterval(async () => {
          await this.fetchMaintenanceAlerts();
        }, 30000);
      }
      const connexionDate: Date = new Date(this.$cookies.get("connexion_date"));
      const today: Date = new Date();
      const diffMs: number = today.getTime() - connexionDate.getTime();

      const diffDays: number = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // days
      const diffHrs: number = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // hours

      if (diffDays > 0 || diffHrs >= 2) {
        this.clearUserCookiesAndPushToLogin();
      }
    }
  },
  async mounted() {
    const observer = new MutationObserver(() => {
      const elements = document.getElementsByClassName("sidebar");
      if (elements.length === 0) return;
      const sidebar = elements[0] as HTMLElement;
      this.sidebarWidth = sidebar.offsetWidth;
    });
    observer.observe(document, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    });

    await this.fetchMaintenanceAlerts();    
  },
  beforeUnmount() {
    clearInterval(this.maintenanceInterval);
    clearTimeout(this.maintenanceTimeout);
  },
  watch: {
    routeName(newVal) {
      if (!newVal) document.title = "Portail Meelo";
      else document.title = `Portail Meelo - ${newVal}`;
    },
    '$route.query.journeyId': function () {
      this.clearResponseStatus();
    },
    isMaintenance(newVal, oldVal) {
      if (newVal) {
        this.wasMaintenance = true;
      } else if (!newVal && oldVal) {
        this.wasMaintenance = true;
        this.maintenanceTimeout = setTimeout(() => {
          this.wasMaintenance = false;
        }, 15000);
      } else {
        this.wasMaintenance = false;
      }
    },
    maintenacesSevices(services) {
      this.maintenanceKey = new Date().getTime() 
      
      if(services && services.length > 0) {
        this.downServices = services
        this.isMaintenance = true
      } else {
        this.downServices = []
        this.isMaintenance = false
      }
    }
  }
})
</script>
<style lang="scss">
@import "@/assets/scss/style.scss";
@import "@/assets/scss/component/sidebar";

#app {
  font-family: $fontFamily;
  color: $blueMeelo;
  min-height: 100vh;
}

/*.App {
  display: grid;
  grid-template-columns: 15% 85%;
  height: 100vh
}

.right-content {
  display: grid;
  grid-template-rows: 100px 1fr;
  overflow-y: scroll;
}

.side-bar {
  overflow: hidden;
}*/

.App {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 1;
  }

  .right-content {
    padding-top: 1.5rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: var(--sidebar-width);
    overflow-y: scroll;
  }

  .right-content-bar {
    padding-top: 0;
  }
}
</style>
