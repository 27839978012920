export default {
  "ACCOUNT_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha conta"])},
    "GENERAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações gerais"])},
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome de usuário"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
    "PHONE_NUMBER_NOT_SPECIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não especificado"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração de autenticação de dois fatores"])},
      "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exigido pela empresa"])},
      "CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar minhas informações"])},
      "MODE": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de autenticação"])},
        "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativado"])}
      }
    },
    "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas informações"])}
  },
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça login"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu e-mail"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua senha"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As credenciais não correspondem a nenhuma conta"])},
    "ATTEMPTS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tentativas consecutivas incorretas bloquearão a sua conta."])},
    "LOCKED_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua conta foi bloqueada."])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueci minha senha"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticação de dois fatores"])},
      "ALERT_MESSAGE_CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso substituirá o seu método de autenticação de dois fatores anterior. Você tem certeza de que deseja continuar?"])},
      "ALERT_MESSAGE_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso ativará a autenticação de dois fatores."])},
      "ALERT_MESSAGE_DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso desativará a autenticação de dois fatores."])},
      "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativar autenticação de dois fatores"])},
      "DISABLE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode desativar a autenticação de dois fatores. No entanto, recomendamos que você mantenha essa funcionalidade ativada para aumentar a segurança da sua conta."])},
      "COMPANY_2FA_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, diga-nos qual método de autenticação você gostaria de usar."])},
      "EMAIL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um código de verificação será enviado para o seu endereço de e-mail."])},
      "SMS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um código de verificação será enviado por SMS. Por favor, insira o seu número de telefone:"])},
      "TITLE_MODAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de autenticação de dois fatores"])},
      "MESSAGE_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você recebeu um"])},
      "FINALISATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para concluir a autenticação de dois fatores, insira o código que foi enviado para você."])}
    }
  },
  "RESET_PASSWORD_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir senha"])},
    "INIT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicializar senha"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu e-mail"])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueci minha senha"])},
    "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha"])},
    "CONFIRM_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar nova senha"])},
    "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir senha"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar e-mail de redefinição de senha"])},
    "SUCCESS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um e-mail de redefinição de senha foi enviado para você."])},
    "EMAIL_NOT_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O e-mail não pôde ser enviado."])},
    "INVALID_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha deve ter pelo menos 8 caracteres, conter uma letra maiúscula, uma letra minúscula, um número e um caractere especial."])},
    "PASSWORD_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As senhas não coincidem."])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua senha foi redefinida com sucesso."])},
    "PASSWORD_RESET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro ao redefinir sua senha."])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao continuar, você concorda com a"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidade"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro nome"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de nascimento"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de e-mail"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone celular"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
    "USAGE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome de uso"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "TITLE_POSTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço postal"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número e nome da rua"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])}
  },
  "COMPANY": {
    "NO_REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum líder e representante conhecido"])},
    "NO_BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum acionista e proprietário conhecido"])},
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidação"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiação"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
    "ORIGIN": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["França"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélgica"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanhol"])}
    },
    "REGISTRATION_NUMBER": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação da empresa ou número SIREN"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro nacional ou Número de IVA"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de Identificação Fiscal"])}
    },
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
    "SIRET_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
    "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
    "MAIN_ACTIVITY_DECLARED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade principal declarada"])},
    "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código NAF ou APE"])},
    "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações da empresa"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação"])},
    "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razão social"])},
    "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma jurídica"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital social"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidade da empresa"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de beneficiários efetivos"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiários efetivos conhecidos"])},
    "DIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiário direto"])},
    "INDIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiário indireto via pessoa jurídica"])},
    "IN_POSITION_SINCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em posição desde"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em hibernação"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimentos em curso"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimentos colectivos"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-reputação"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota global"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de opiniões"])},
    "WORDING_CLOUD_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recorrência de palavras nas opiniões"])},
    "ADJECTIVES_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiniões positivas / Opiniões negativas nas opiniões"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de estabelecimentos"])},
    "ESTABLISHMENTS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de estabelecimentos"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de representantes"])},
    "REPRESENTATIVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representantes"])},
    "REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líderes e representantes conhecidos"])},
    "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acionistas e beneficiários conhecidos"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor recomendado"])},
    "HEADQUARTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sede"])},
    "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posição"])},
    "TOTAL_SHARE_HOLDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participação total"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de origem da empresa"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa física"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa jurídica"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente do conselho de administração"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente e Diretor Executivo"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente não associado"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-gerente"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diretor executivo"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diretor geral não administrador"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diretor executivo único"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diretor executivo delegado"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice-presidente"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diretor"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diretor adjunto"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente do conselho executivo"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente do conselho de supervisão"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sócio-gerente"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlador de gestão"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auditor estatutário - titular"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auditor estatutário - suplente"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auditor estatutário"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidante"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador provisório"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante na França de uma empresa estrangeira"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membro do conselho de administração"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presidente do conselho de administração"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociedade"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investimento coletivo"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de interesse econômico"])}
    },
    "OF_SHARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de ações"])},
    "OF_VOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de votos"])},
    "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações legais"])},
    "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Força de trabalho"])},
    "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro de IVA"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de encerramento"])},
    "ACTIVITY_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de atividade"])},
    "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
    "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
    "UNVERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não domiciliado"])},
    "RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendação"])},
    "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo de atividade"])},
    "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estabelecimentos"])},
    "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escritório"])},
    "IN_OPERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em operação"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechado"])},
    "FINANCES": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanças da empresa"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma informação financeira disponível"])},
      "RATIOS": {
        "grossMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margem bruta (€)"])},
        "netMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margem líquida (€)"])},
        "valueAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor adicionado (€)"])},
        "ebitda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucro antes de juros, impostos, depreciação e amortização (€)"])},
        "ebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucro operacional (€)"])},
        "profitBeforeTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucro antes de impostos (€)"])},
        "extraordinaryResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado extraordinário (€)"])},
        "netIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucro líquido (€)"])},
        "shortTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dívidas de curto prazo (€)"])},
        "longMidTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dívidas de longo e médio prazo (€)"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturamento (€)"])},
        "balanceSheetTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total do balanço (€)"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital e reservas (€)"])},
        "workingCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital de giro (€)"])},
        "workingCapitalRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisito de capital de giro (€)"])},
        "financialLiquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidez financeira (€)"])},
        "intangibleAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos intangíveis (€)"])},
        "tangibleFixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos fixos tangíveis (€)"])},
        "participatingInterestsAndRelatedDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participações e dívidas a receber relacionadas (€)"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoque (€)"])},
        "otherDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras dívidas a receber (€)"])},
        "tradeDebtorsAndRelatedAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas a receber comerciais e contas relacionadas (€)"])},
        "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financeiro (€)"])},
        "chargesCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custos (€)"])},
        "appropriationsToDepreciationAndProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apropriações para depreciação e provisões (€)"])},
        "totalExtraordinaryIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de receitas extraordinárias (€)"])},
        "totalExtraordinaryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de despesas extraordinárias (€)"])},
        "corporateTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto de renda corporativo (€)"])},
        "operatingLiabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passivos operacionais (€)"])},
        "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital (€)"])},
        "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva (€)"])},
        "profitOrLossForTheFinancialYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucro ou prejuízo do exercício (€)"])},
        "investmentGrantsTaxRegulatedProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subvenções de investimento e provisões fiscais regulamentadas (€)"])},
        "loansAndDebtsPayableToCreditInstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empréstimos e dívidas a pagar a instituições de crédito (€)"])},
        "loansAndSundryFinancialDebtsPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empréstimos e outras dívidas financeiras a pagar (€)"])},
        "changeInStocksIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudança nos rendimentos de estoque (€)"])},
        "purchasesOfGoodsForResale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras de mercadorias para revenda (€)"])},
        "changeInStocksCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudança nos custos de estoque (€)"])},
        "otherExternalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outros serviços externos (€)"])},
        "taxesLeviesAndSimilarPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostos, taxas e pagamentos similares (€)"])},
        "personnelCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custos de pessoal (€)"])},
        "otherCurrentOperatingCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras despesas operacionais correntes (€)"])},
        "financialIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento financeiro (€)"])},
        "financialCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas financeiras (€)"])},
        "fpTb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundos próprios sobre o total do balanço (%)"])},
        "supplierDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prazos de pagamento a fornecedores (dias)"])},
        "customerDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prazos de pagamento a clientes (dias)"])},
        "caf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacidade de autofinanciamento (€)"])},
        "rnCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucro líquido em relação ao faturamento (%)"])},
        "etp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivalente a tempo inteiro"])},
        "dividends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividendos pagos aos acionistas (€)"])},
        "financialBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo financeiro (€)"])},
        "salesGrowthRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de crescimento das vendas (%)"])},
        "grossMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de margem bruta (%)"])},
        "ebitdaMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de margem de EBITDA (%)"])},
        "operatingMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de margem operacional (%)"])}
      },
      "BLOCKS": {
        "intermediateOperationTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldos intermediários de gestão"])},
        "debts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dívidas"])},
        "balanceSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanço"])},
        "fixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos fixos"])},
        "currentAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativos circulantes"])},
        "profitAndLossAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta de resultados"])},
        "balancePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passivo do balanço"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital e reservas"])},
        "operatingIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita operacional"])},
        "operatingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custos operacionais"])},
        "operatingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado operacional"])},
        "ratios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratios financeiros"])},
        "increase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crescimento"])}
      }
    },
    "LEGAL_DOCUMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos legais"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum documento legal disponível"])},
      "NO_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para consultar o documento, por favor, faça o download."])}
    },
    "BODACC_ANNOUNCEMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolução da empresa"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "NATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natureza"])},
      "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação"])},
      "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhe"])}
    },
    "ANNUAL_ACCOUNTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas anuais"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta anual disponível"])},
      "COMPANY_FINANCIAL_STATEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demonstrações financeiras da empresa"])}
    },
    "CHECKS": {
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de um relatório fiscal depositado na mesma data"])},
      "LESS_THAN_X_YEARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa com menos de 3 anos"])},
      "MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudança de gestão inferior a 3 anos"])},
      "SHARE_CAPITAL_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudança de capital em menos de 3 anos"])},
      "HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência de sede em menos de 3 anos"])},
      "ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudança de atividade em menos de 3 anos"])},
      "MULTI_MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de 2 mudanças na gestão nos últimos 10 anos"])},
      "HAS_CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessação de atividade"])},
      "HAS_ALL_ESTABLISHMENTS_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os estabelecimentos estão fechados"])},
      "HAS_ACTIVITY_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de duas mudanças de atividade pelo menos nos últimos 10 anos"])},
      "HAS_HEADQUARTER_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de duas mudanças de sede nos últimos 10 anos"])},
      "LOCATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localização arriscada"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa domiciliada"])},
      "WEBSITE_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de registro do domínio do site"])},
      "EMAIL_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de registro do domínio de email"])},
      "EMAIL_DOMAIN_EQUALS_WEBSITE_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência entre o domínio de email e o domínio do site"])},
      "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiação"])},
      "COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processos coletivos"])},
      "OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras empresas em processos coletivos"])},
      "OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras empresas irradiadas"])},
      "ACTIVITIES_CODE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setor de atividade arriscado"])},
      "HEADQUARTER_DISTANCE_TOO_HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distância significativa entre o endereço da sede e a residência do gerente"])},
      "LEGAL_SITUATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de litígios"])},
      "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de inatividade superior a 3 anos"])},
      "MULTI_HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de 2 transferências de sede nos últimos 10 anos"])},
      "MULTI_ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de 2 mudanças de atividade nos últimos 10 anos"])},
      "CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessação de atividade"])},
      "ALL_ESTABLISHMENTS_ARE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as instalações estão fechadas"])},
      "CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactabilidade de dados"])},
      "HEADQUARTER_ADDRESS_IN_WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraude detectada neste endereço"])},
      "MULTI_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de um registro desde a criação"])},
      "MORE_THAN_ONE_ESTABLISHMENT_DOMICILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos um estabelecimento está domiciliado"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SUBMISSION_DATE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de um relatório fiscal com uma data de submissão encerrada"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE_AND_YEAR_DIFFERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de um relatório fiscal com a mesma data de submissão e um ano diferente"])},
      "MORE_THAN_TEN_TAX_REPORTS_WITH_DIFFERENT_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais de dez relatórios fiscais com datas de submissão diferentes"])},
      "RECENT_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedimentos coletivos recentes"])}
    },
    "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicílio"])},
    "BRANCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filial"])},
    "CHECK_EMPLOYER": {
      "NO_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A informação não pôde ser verificada"])},
      "FALSE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A empresa não foi retirada da lista"])},
      "TRUE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A empresa foi retirada da lista"])},
      "COLLECTIVE_PROCEEDING": {
        "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foram detectados processos colectivos pendentes"])},
        "MORE_THAN_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ou mais processos colectivos pendentes detectados"])},
        "RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ou mais processos colectivos em curso detectados, incluindo pelo menos um recente (< 3 anos)"])}
      }
    }
  },
  "ERRORS": {
    "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops! Parece que há um problema. Nossa equipe já está trabalhando para resolvê-lo. Por favor, tente novamente mais tarde."])},
    "NOT_FOUND_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de identificação inserido não existe."])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma informação recuperada"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nossa ferramenta de processamento de declaração fiscal não aceita documentos anteriores a 2018."])},
    "TAX_REPORT_TOO_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração fiscal muito antiga"])},
    "DOCUMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro durante a leitura do documento"])},
    "COMPANY_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de número de identificação inserido não está associado a nenhuma empresa ativa."])},
    "ENRICHMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma informação encontrada."])},
    "ERROR_OCCURRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro, por favor, tente novamente mais tarde"])},
    "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum dado encontrado, por favor, inicie uma nova análise"])},
    "CALL_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chamada concluída, observe que alguns dados estão inacessíveis. Para um relatório completo, por favor, inicie uma nova análise"])},
    "SIREN_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise concluída, nenhum dado encontrado com o número de identificação fornecido"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum dado recuperado"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum dado disponível"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontramos um erro com o número de telefone inserido. Verifique as informações inseridas"])},
    "UNPROCESSABLE_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro, por favor tente novamente em uma nova pasta ou entre em contato com o suporte"])},
    "CANNOT_ACCESS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pode acessar esta pasta."])}
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão"])},
    "VALIDATE_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar Informações"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de Documento"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar Open Banking"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar a jornada de Open Banking no navegador"])},
    "FINISH_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar Análise"])},
    "NEW_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar uma Nova Análise"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar Resumo"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "DETAILS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da Análise"])},
    "VERIFY_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar Endereço"])},
    "SEND_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar SMS"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar E-mail"])},
    "BACK_HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para a Página Principal"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML/CFT"])},
    "VALIDATE_AND_SEND_SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar e Enviar SMS"])},
    "VALIDATE_AND_SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar e Enviar E-mail"])},
    "IFRAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
    "RUN_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executar Análise"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de IBAN"])},
    "FRAUD_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuações de Fraude"])},
    "SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
    "SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionado"])},
    "CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultar"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher"])},
    "DEACTIVATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativar"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "LINK_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link por SMS"])},
    "LINK_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link por e-mail"])},
    "ENGLISH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versão em Inglês"])},
    "FRENCH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versão em Francês"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "UPLOAD_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar aqui"])},
    "MY_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha empresa"])},
    "MY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus usuários"])},
    "CONSUMPTION_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoramento de Consumo"])},
    "EXTRACT_MEMORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrair Memória"])},
    "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor de Regras"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lote"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha conta"])},
    "CREATE_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar um usuário"])},
    "UNBLOCK_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear um usuário"])},
    "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
    "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
    "CONFIRM_AND_CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar e continuar"])},
    "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar"])},
    "MODIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinição de senha"])},
    "ACTIVATE_DEACTIVATE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar / Desativar conta"])},
    "RESETTING_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetar chave de API"])},
    "DELETE_THE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir a conta"])},
    "RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno"])},
    "NEW_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova solicitação"])},
    "RESTART_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar a solicitação"])},
    "LINK_BROWSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do navegador"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "CONSULT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulte a análise da Finovox"])},
    "DEPLOY_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementar tudo"])},
    "REDUCE_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolher todos os"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ir para o LinkedIn"])},
    "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de identidade"])},
    "SCORE_COMPANY_FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de fraude empresarial"])},
    "SELECT_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar tudo"])},
    "MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha equipe"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
    "COMPLETE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluir o upload"])},
    "LIVE_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminação de dúvidas"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requerente"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificável"])},
  "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas de e-mail vinculadas"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas de telefone vinculadas"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados do requerente"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de contato"])},
  "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do cliente"])},
  "VERIFICATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de documento"])},
  "VERIFICATION_TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de aviso fiscal"])},
  "CONFIDENCE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Confiança em Fraude de Identidade"])},
  "SMB_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Confiança em Fraude de Identidade Empresarial"])},
  "CONFIDENCE_INDEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice de Confiança em Fraude de Identidade"])},
  "DETAILED_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da pontuação"])},
  "CONFIDENCE_EXPLICATIONS": {
    "UNDER_TWENTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados fornecidos não relacionados à pessoa"])},
    "BETWEEN_TWENTY_AND_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados duvidosos, recomenda-se verificação de identidade"])},
    "ABOVE_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados fornecidos existentes e relacionados à pessoa"])}
  },
  "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de documentos"])},
  "ANALYSIS_RESULT_RETRIEVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperando resultados de análise em andamento ..."])},
  "UPLOAD_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload do documento concluído, você pode fechar esta janela."])},
  "FACE_MATCHING_LINK_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um link foi enviado para você por SMS, verifique seu telefone"])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vínculo com a empresa"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência de identidade"])},
  "COMPANY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência com a empresa"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outra pessoa"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situação"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum beneficiário efetivo conhecido."])},
    "NO_REPRESENTATIVES_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum representante conhecido."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum líder encontrado..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os dados foram transmitidos com sucesso. Você pode fechar esta página para ver o resultado da análise."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "RESULT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do OpenBanking"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise detalhada do OpenBanking"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saídas"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da conta"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salário médio mensal"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel médio mensal"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo atual"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, peça ao seu cliente para ter seu smartphone e informações bancárias em mãos."])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "FINANCIAL_ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de análise financeira"])},
    "ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período analisado"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["até"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor máximo de transações"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da transação máxima"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal total"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Salários"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data média mensal - Salários"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Juros"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Transferências"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Aluguéis"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Retiradas em caixas eletrônicos"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Seguros"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Serviços públicos"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Compras de alimentos"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Consumo de alimentos"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Compras"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Saúde/Bem-estar"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Transportes"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Assinaturas de telecomunicações"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor médio mensal - Assinaturas de áudio-visual"])},
      "MONTH_INCOMES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de rendimentos"])},
      "MONTH_INCOMES_SALARIES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de salários"])},
      "MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de subsídios"])},
      "MONTH_INCOMES_PENSIONS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de pensões"])},
      "MONTH_INCOMES_AMOUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal mais alta"])},
      "MONTH_INCOMES_AMOUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal mais baixa"])},
      "MONTH_OUTCOMES_RENTS_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de aluguéis"])},
      "MONTH_OUTCOMES_CREDITS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de créditos"])},
      "MONTH_OUTCOMES_CREDITS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data média de vencimento de créditos"])},
      "MONTH_OUTCOMES_PENSIONS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de pagamento de pensão"])},
      "RECOMMENDED_DEBIT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ideal para débito"])},
      "RECOMMENDED_DEBIT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor ideal para débito"])},
      "MONTHLY_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mensal"])},
      "MONTH_OUTCOMES_RENTS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mínimo de aluguéis"])},
      "MONTH_OUTCOMES_RENTS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor máximo de aluguéis"])},
      "MONTH_OUTCOMES_UTILITIES_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mínimo de serviços públicos"])},
      "MONTH_OUTCOMES_UTILITIES_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor máximo de serviços públicos"])},
      "MONTH_OUTCOMES_CREDITS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mínimo"])},
      "MONTH_OUTCOMES_CREDITS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor máximo"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número mínimo de débitos por mês"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número máximo de débitos por mês"])},
      "BANK_CARD_AND_DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão(ões) bancário(s) e débitos"])},
      "DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débitos"])},
      "BANK_CARD_PRESENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão(ões) bancário(s) vinculado(s)"])},
      "BANK_CARD_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade de cartões que realizaram transações de saída"])},
      "BANK_CARD_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Números de cartões identificados para transações de saída"])},
      "MONTH_NEGATIVE_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data mais antiga"])},
      "MONTH_NEGATIVE_BALANCE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data mais recente"])},
      "MAX_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data mínima do mês mais cedo em que o saldo é máximo"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentual de gastos em relação à renda"])},
      "MONTH_NEGATIVE_BALANCE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data média de saldo negativo na conta"])},
      "AVERAGE_MONTHLY_ENTRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de entradas"])},
      "TOTAL_FOR_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total para o período"])},
      "AVERAGE_MONTHLY_TAX_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita fiscal mensal média"])},
      "AVERAGE_MONTHLY_EXITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de saídas"])},
      "ANNUAL_NET_INCOME_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor do rendimento líquido anual"])},
      "ANNUAL_REVENUE_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor do faturamento anual"])},
      "MONTHLY_TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor mensal dos impostos"])},
      "AVERAGE_MONTHLY_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal da folha de pagamento"])},
      "OTHER_ACCOUNTS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras contas"])},
        "MONTH_INCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renda média mensal de poupança"])},
        "MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado médio mensal de poupança"])},
        "MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de transferências recebidas"])},
        "MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média mensal de transferências enviadas"])}
      }
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de endividamento"])},
    "MONTH_OUTCOMES_CREDITS_COUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número médio de créditos em andamento"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resta viver"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de início do período de análise financeira"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de término do período de análise financeira"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo mensal"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta Conjunta Principal"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de débito recomendada"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisão Bancária"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de transações"])},
    "MOVEMENT_SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo dos Movimentos"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])},
    "ACCOUNT_STATEMENT": {
      "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver extrato de conta"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REKENING(EN) AFSCHRIFT(EN)"])},
      "ACCOUNT": {
        "CHECKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTA CORRENTE"])},
        "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTA POUPANÇA"])}
      },
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação pendente"])},
      "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foram encontrados dados, por favor inicie uma nova pesquisa"])},
      "PRIVACY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por razões de segurança e proteção de dados, o extrato de conta OpenBanking deixa de estar disponível após um determinado período de tempo."])},
      "CATEGORY": {
        "INCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento de Publicidade"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsídio"])},
          "ALLOWANCE": {
            "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsídio Familiar"])},
            "UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsídio de Desemprego"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro Subsídio"])}
          },
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Audiovisual"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automóvel"])},
          "AUTOMOBILE": {
            "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenção de Automóvel"])},
            "PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estacionamento de Automóvel"])}
          },
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência Automática"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa Bancária"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolha de Dinheiro"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheque"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito"])},
          "CREDIT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito ao Consumidor"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito à Habitação"])}
          },
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptomoeda"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento Cultural"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra de Dados"])},
          "DEPOSIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doação e Caridade"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educação"])},
          "EDUCATION": {
            "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educação para Condução"])},
            "SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educação Escolar"])}
          },
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de Multa"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Fitness"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de Alimentos"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência Fraudulenta"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogo"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercearia"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saúde e Bem-estar"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospedagem"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indenização"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juros"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência Internacional"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de Intervenção"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investimento"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa Legal"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lazer"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensão"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuidados de Animais de Estimação"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Leitura"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar"])},
          "REJECT": {
            "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar Crédito"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro Rejeitar"])}
          },
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel"])},
          "RENT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel para Consumidor"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel para Habitação"])}
          },
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salário"])},
          "SALARY": {
            "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salário Regular"])},
            "TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salário Temporário"])}
          },
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poupança"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Serviço de Segurança"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de Apreensão"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Software"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado de Ações"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sindicato"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Telecomunicações"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporte"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não Pago"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilidade"])},
          "UTILITY": {
            "ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Energia"])},
            "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Água"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outra Utilidade"])}
          },
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videojogo"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])}
        },
        "OUTCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento de Publicidade"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsídio"])},
          "ALLOWANCE": {
            "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsídio Familiar"])},
            "UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsídio de Desemprego"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro Subsídio"])}
          },
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Audiovisual"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automóvel"])},
          "AUTOMOBILE": {
            "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenção de Automóvel"])},
            "PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estacionamento de Automóvel"])}
          },
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência Automática"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa Bancária"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolha de Dinheiro"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheque"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito"])},
          "CREDIT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito ao Consumidor"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito à Habitação"])}
          },
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptomoeda"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento Cultural"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra de Dados"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doação e Caridade"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educação"])},
          "EDUCATION": {
            "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educação para Condução"])},
            "SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educação Escolar"])}
          },
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de Multa"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Fitness"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de Alimentos"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência Fraudulenta"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogo"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercearia"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saúde e Bem-estar"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospedagem"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indenização"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juros"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência Internacional"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de Intervenção"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investimento"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa Legal"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lazer"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensão"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuidados de Animais de Estimação"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Leitura"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar"])},
          "REJECT": {
            "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar Crédito"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro Rejeitar"])}
          },
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel"])},
          "RENT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel para Consumidor"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel para Habitação"])}
          },
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salário"])},
          "SALARY": {
            "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salário Regular"])},
            "TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salário Temporário"])}
          },
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poupança"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Serviço de Segurança"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de Apreensão"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Software"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado de Ações"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sindicato"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Telecomunicações"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporte"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não Pago"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilidade"])},
          "UTILITY": {
            "ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Energia"])},
            "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Água"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outra Utilidade"])}
          },
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videojogo"])},
          "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levantamento"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])}
        }
      }
    },
    "RECOMMENDATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendações"])},
    "APPROVALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovações"])},
    "INDEBTEDNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endividamento"])},
    "HABITAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitação"])},
    "EXISTING_CREDIT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análises de Crédito Existente"])},
    "ALERTS": {
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "CAUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causa"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma alerta foi levantada"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas"])}
    },
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços"])},
    "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Água"])},
    "GAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gás"])},
    "ELECTRICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eletricidade"])},
    "BANK_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartões Bancários"])},
    "DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detecção de Débitos Diferidos"])},
    "GLOBAL_TRANSACTION_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de transações"])},
    "MONTH_NEGATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passagem para o negativo no período"])},
    "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta Principal"])},
    "NO_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma transação de renda foi detectada."])},
    "ALERT": {
      "ABSENCE_OF_INCOME": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausência de Renda"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma transação de renda foi detectada"])}
      },
      "PRESENCE_OF_REJECTS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Rejeições"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com rejeições foi detectada"])}
      },
      "PRESENCE_OF_UNPAIDS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Pendências"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com pendências foi detectada"])}
      },
      "PRESENCE_OF_PENSIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Aposentadorias"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com aposentadorias foi detectada"])}
      },
      "PRESENCE_OF_GAMBLINGS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Apostas"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com apostas foi detectada"])}
      },
      "PRESENCE_OF_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Auxílios"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com auxílios foi detectada"])}
      },
      "PRESENCE_OF_SEIZURE_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Taxas de Penhora"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com taxas de penhora foi detectada"])}
      },
      "MONTH_NEGATIVE_BALANCE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo Negativo no Mês"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectamos um ou mais dias com saldo negativo"])}
      },
      "BANK_WITH_EXTREME_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco com Risco Extremo"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectamos uma ou mais contas de um banco considerado de risco extremo"])}
      },
      "BANK_WITH_HIGH_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco com Risco Alto"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectamos uma ou mais contas de um banco considerado de risco alto"])}
      },
      "TRANSACTION_HISTORY_DATE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do Histórico de Transações"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menos de 2 meses de histórico de transações foram transmitidos para esta análise"])}
      },
      "TRANSACTION_HISTORY_DATE_ONE_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do histórico de transações"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menos de 1 mês de histórico de transações foi transmitido para esta análise"])}
      },
      "PRESENCE_OF_CASH_COLLECTIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de empresas de cobrança"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com uma agência de cobrança detectada"])}
      },
      "PRESENCE_OF_CRYPTOCURRENCIES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Criptomoedas"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com criptomoedas foi detectada"])}
      },
      "TRANSACTION_HISTORY_COUNT": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Operações no Histórico de Transações"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menos de 10 transações foram transmitidas para esta análise"])}
      },
      "PRESENCE_OF_CREDITS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Créditos por Mês"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 3 transações de crédito por mês foram detectadas"])}
      },
      "PRESENCE_OF_INTERVENTION_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Taxas de Intervenção"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com taxas de intervenção foi detectada"])}
      },
      "PRESENCE_OF_WITHDRAWALS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Saques por Mês"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 7 transações de saque por mês foram detectadas"])}
      },
      "PRESENCE_OF_INTERNATIONALS_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de Transferências Internacionais"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com transferências internacionais foi detectada"])}
      },
      "TRANSACTION_HISTORY_FOR_ANALYSIS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de Transações para Análise"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de transações transmitidas não é suficiente para uma análise oportuna"])}
      },
      "ABSENCE_OF_TAXE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausência de imposto"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma transação de imposto foi detectada"])}
      },
      "LOSS_OF_SALARY": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perda de salário"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foi detectada uma perda de salário"])}
      }
    },
    "NET_INCOME_AND_ANNUAL_TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renda líquida e faturamento anual"])},
    "TAXES_AND_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostos e Folha de Pagamento"])},
    "DAILY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanço diário"])},
    "MONTHLY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanço mensal"])},
    "DAILY_AND_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanço diário e mensal"])},
    "WRONG_ANALYSIS_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenção, a análise foi realizada em uma conta pessoal e não em uma conta empresarial"])},
    "WRONG_ANALYSIS_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenção, a análise foi realizada em uma conta empresarial e não em uma conta pessoal"])},
    "PROVIDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro durante a recuperação de dados. Inicie o ficheiro novamente."])}
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência de Imagens"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagens Capturadas"])},
    "NO_IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma imagem encontrada"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combate à Lavagem de Dinheiro e ao Terrorismo"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelamento de Ativos"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa Politicamente Exposta"])},
    "PPE_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta pessoa é identificada como politicamente exposta"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mídia Adversa"])},
    "ADVERSE_MEDIA_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existem várias reportagens de mídia sobre o requerente."])},
    "ADVERSE_MEDIA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Mídia"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "SANCTIONED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sancionada"])},
    "SANCTIONED_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta pessoa foi identificada como sancionada."])},
    "SANCTIONED_EXPLANATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso pode envolver o congelamento de seus ativos, ligação com o terrorismo e/ou lavagem de dinheiro."])},
    "CLASS_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chefes de Estado, membros do executivo nacional, membros das legislaturas nacionais, altos funcionários militares e da aplicação da lei, altos funcionários de agências nacionais, membros dos conselhos de administração de bancos centrais, altos funcionários do sistema judicial (nacional), Principais - altos funcionários de partidos políticos"])},
    "CLASS_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membros do conselho de administração de organizações internacionais e federações esportivas internacionais, embaixadores, altos comissários e outros cargos diplomáticos de alto nível, membros do executivo regional, membros das legislaturas regionais, altos funcionários judiciais (nível regional)"])},
    "CLASS_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção geral e conselho de administração de empresas e organizações públicas"])},
    "CLASS_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefeitos e membros de governos locais (nível sub-regional)"])},
    "LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nível"])},
    "ADVERSE_MEDIA_DETAILS": {
      "financial-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crime Financeiro"])},
      "violent-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crime Violento"])},
      "sexual-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crime Sexual"])},
      "terrorism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrorismo"])},
      "fraud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraude"])},
      "narcotics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entorpecentes"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geral"])},
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriedade"])},
      "financial-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Financeiro"])},
      "fraud-linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado à Fraude"])},
      "narcotics-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Entorpecentes"])},
      "violence-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Violência"])},
      "cybercrime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibercrime"])},
      "general-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Geral"])},
      "regulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulatório"])},
      "financial-difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dificuldades Financeiras"])},
      "violence-non-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violência Não AML CFT"])},
      "other-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro Financeiro"])},
      "other-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro Sério"])},
      "other-minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro Menor"])}
    },
    "LINKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta pessoa não está identificada como sancionada nem politicamente exposta."])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa com um processo judicial em andamento."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa colocada em espera e reativada desde então."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação do telefone móvel."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagens encontradas no Google relacionadas às informações fornecidas."])},
    "EMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação do e-mail."])},
    "AVAILABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagens disponíveis em redes sociais em relação às informações do requerente."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de estabelecimentos detidos pela empresa"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim, se houver congelamento de ativos em andamento."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Fraude na existência e confiabilidade dos dados de contato do requerente."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Risco na análise financeira e solvabilidade da empresa."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medida do risco de inadimplência da estrutura."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuações atualizadas em"])},
    "SCORE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pontuação avalia a solidez financeira da empresa com base em seu setor de atuação e balanços financeiros."])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Risco na análise financeira da conta bancária."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo mensal médio durante o período estudado."])},
    "EMAIL_DISPOSABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email descartável"])},
    "EMAIL_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail arriscado"])},
    "MOBILE_PHONE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone vinculado"])},
    "EMAIL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail vinculado"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar a inserção do processo"])},
    "VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultar o processo"])},
    "DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasta duplicada"])},
    "DELETE_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir a pasta"])},
    "BLOCK_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossível reabrir um processo concluído."])}
  },
  "EMAIL_DISPOSABLE": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O e-mail fornecido está associado a um domínio descartável."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlar documentos"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher um tipo de documento"])},
    "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de Identificação"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de Identificação"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta de Condução"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorização de Residência"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Empresa o Situación"])},
    "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Empresa Individual"])},
    "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Empresa"])},
    "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidade - Análise de não alteração digital"])},
    "AUTHENTICITY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes de autenticidade"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração Fiscal"])},
    "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração Fiscal Simples"])},
    "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração Fiscal complexa"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de imposto - Rendimentos ou propriedades"])},
    "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto de Renda"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro tipo"])},
    "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto Predial"])},
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados Bancários"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlo de Documentos"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise do documento falhou"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, complete o carregamento do seu documento na janela à esquerda para poder consultar a análise do documento."])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracheque"])},
    "PAYSLIP_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracheque"])},
    "RIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
    "KBIS_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS"])},
    "TAX_NOTICE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de imposto"])},
    "OTHER_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro documento"])},
    "NEW_DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisar um novo documento"])},
    "NEW_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação biométrica"])},
    "FACE_MATCHING_DESC_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseja realizar uma nova análise de identidade com verificação biométrica?"])},
    "FACE_MATCHING_DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, insira o número de telefone do cliente para que ele possa receber por SMS o link para realizar sua análise."])},
    "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de identidade"])},
    "ID_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode iniciar aqui uma análise documental em documentos de identidade como carteiras de identidade nacionais, passaportes, vistos de residência e carteiras de motorista."])},
    "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de documento"])},
    "DOCUMENT_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode iniciar aqui uma análise documental para uma declaração fiscal, KBIS, contracheque, aviso de imposto ou qualquer outro documento em formato PDF."])},
    "SELECT_DOCUMENT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um ou mais tipos de documento"])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconhecido"])},
    "UNKNOWN_DOC_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O documento que passou não pôde ser identificado como um documento de identidade. Para verificar, lembre-se de fazer o upload da frente e do verso do documento ou de utilizar o painel de análise do documento."])},
    "NO_DOCUMENT_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum documento carregado."])},
    "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle biométrico"])},
    "SELFIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformidade"])},
      "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidade"])},
      "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova análise de identidade"])},
      "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova análise documental"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da Análise"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
        "WAITING_CONFIRMATION_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise aguardando sua confirmação"])},
        "IN_PROGRESS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise em progresso"])},
        "LIVE_CHECK": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar LiveCheck"])},
          "DESC_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você está prestes a iniciar o LiveCheck para o documento <", _interpolate(_named("documentName")), ">."])},
          "DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verificação humana está disponível apenas durante os primeiros 10 minutos após o upload do documento."])},
          "DESC_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O processamento da análise pode levar algum tempo. Você será notificado quando a análise for concluída."])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La chiarificazione dei dubbi ti consente di richiedere una verifica manuale del documento da parte di un operatore umano in meno di quindici minuti. Attenzione, è disponibile solo per 10 minuti dopo il caricamento del documento."])},
          "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck em andamento"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do liveCheck disponível"])},
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck em processamento."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As verificações abaixo serão atualizadas assim que a análise manual for concluída. Por favor, aguarde."])},
          "FAILED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do documento LiveCheck não disponível."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro técnico ao iniciar a análise do documento LiveCheck."])},
          "SUCCESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do documento LiveCheck concluída."])},
          "ANALYSIS_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado da análise"])},
          "ANALYST_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem do analista"])}
        },
        "CHECKS": {
          "EMISSION_COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência da nacionalidade do documento"])},
          "EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência do tipo de documento / data de emissão"])},
          "EXPIRATION_DATE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade da data de expiração"])},
          "VALIDITY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento válido"])},
          "MRZ_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação MRZ"])},
          "MRZ_CHECKSUMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação MRZ"])},
          "MRZ_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação MRZ"])},
          "MRZ_ALIGNEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação MRZ"])},
          "MRZ_CLASSIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação MRZ"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência da identidade da pessoa / MRZ"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência da identidade da pessoa / MRZ"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência da identidade da pessoa / MRZ"])},
          "DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do documento consistente com MRZ"])},
          "EXPIRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de expiração consistente com MRZ"])},
          "OCR_EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de emissão consistente com MRZ"])},
          "PERSONAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação pessoal consistente com MRZ"])},
          "PHOTO_CONFORMITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise da foto"])},
          "PHOTO_LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise da foto"])},
          "VISUAL_SECURITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformidade dos pontos de segurança do documento"])},
          "PHOTOCOPY_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detecção de fotocópia"])},
          "SCREENSHOT_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detecção de captura de tela"])},
          "OLD_DRIVING_LICENSE_DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número antigo do formato da carta de condução"])},
          "DRIVING_LICENSE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade da carta de condução"])},
          "DRIVING_LICENSE_DIGITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade da carta de condução"])},
          "AGE_AT_CERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idade na obtenção consistente"])},
          "MRZ_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação MRZ"])},
          "MRZ_IDENTITY_COHERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência da identidade da pessoa"])},
          "PICTURE_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise da foto"])},
          "BARCODE_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de barras encontrado"])},
          "BARCODE_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados do código de barras codificados corretamente"])},
          "BARCODE_SIGNATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de código de barras válida"])},
          "TYPE_RECOGNIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo de documento foi reconhecido"])},
          "NOT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de validade"])},
          "MRZ_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A zona legível por máquina (MRZ) do documento é válida"])},
          "VALIDITY_PERIOD_MODEL_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O modelo de período de validade corresponde"])},
          "OCR_ANALYSIS_MRZ_FIRST_NAMES_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os primeiros nomes na MRZ correspondem à análise OCR"])},
          "OCR_ANALYSIS_MRZ_DOCUMENT_NUMBER_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número do documento na MRZ corresponde à análise OCR"])},
          "DOCUMENT_ASPECT_GENERAL_APPEARANCE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A aparência geral do documento corresponde às expectativas"])},
          "SECURITY_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos de segurança presentes no documento"])},
          "QUALITY_ABOVE_MINIMUM_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A qualidade do documento está acima do limiar mínimo"])},
          "NOT_SPECIMEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O documento não é um espécime"])},
          "FRONT_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O lado frontal do documento foi encontrado"])},
          "OCR_ANALYSIS_MRZ_BIRTH_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome de nascimento na MRZ corresponde à análise OCR"])},
          "OCR_ANALYSIS_MRZ_BIRTH_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento na MRZ corresponde à análise OCR"])},
          "OCR_ANALYSIS_FAITHFUL_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise OCR indica o uso de uma fonte fiel no documento"])},
          "DOCUMENT_ASPECT_PICTURE_PRESENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma imagem está presente no documento"])},
          "OCR_ANALYSIS_EXPIRATION_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de expiração foi lida com sucesso através da análise OCR"])},
          "OCR_ANALYSIS_ISSUANCE_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de emissão foi lida com sucesso através da análise OCR"])},
          "ISSUANCE_AND_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As datas de emissão e expiração coincidem"])},
          "NO_TAMPERING_SIGN_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum sinal de adulteração foi encontrado no documento"])},
          "NO_ANNOTATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma anotação foi encontrada no documento"])},
          "BACK_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O verso do documento foi encontrado"])},
          "OCR_ANALYSIS_MRZ_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de expiração no MRZ corresponde à análise OCR"])},
          "OCR_ANALYSIS_MRZ_ISSUANCE_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de emissão no MRZ corresponde à análise OCR"])},
          "OCR_ANALYSIS_MRZ_LAST_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O sobrenome no MRZ corresponde à análise OCR"])},
          "DOC_2D_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento 2D válido"])},
          "DOC_2D_AND_DOCUMENT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento 2D coerente com as informações do documento"])},
          "WORKABLE_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A captura pode ser processada para verificação"])},
          "WORKABLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O elemento pode ser processado para verificação"])},
          "STATUS_ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiê aceito"])},
          "FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento fraudulento"])},
          "DOCUMENT_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A qualidade da captura do documento não permite revisão humana"])},
          "DOCUMENT_INCORRECTLY_FRAMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O documento não está corretamente enquadrado na imagem"])},
          "DOCUMENT_SIDE_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas um lado do documento está visível (ambos os lados são necessários)"])},
          "DOCUMENT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento expirado"])},
          "DOCUMENT_UNSUPPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de documento não suportado"])},
          "DOCUMENT_COUNTRY_UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de emissão do documento não suportado"])},
          "DOCUMENT_UNSUPPORTED_RECEIPT_ACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de documento não suportado"])},
          "BIOMETRY_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A qualidade dos dados biométricos não permite revisão humana"])},
          "LIVE_CHECK_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro técnico ocorrido com o provedor"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro motivo, ver detalhes abaixo"])},
          "ID_PHOTO_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência entre o selfie do usuário e o documento"])},
          "PASSIVE_LIVENESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condições para a captura do selfie atendidas"])},
          "IS_ALIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimentos detectados durante a captura do selfie"])},
          "SEQUENCE_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimentos consistentes durante a execução"])},
          "ORIGINAL_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento original apresentado"])},
          "PICTURE_NOT_TAMPERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto de identidade não adulterada"])},
          "DOCUMENT_NUMBER_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade do número do documento"])}
        },
        "KBIS": {
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de registro"])},
          "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da empresa"])},
          "HEAD_QUARTER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço da sede"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
          "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
          "PUBLICATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de publicação do KBIS"])},
          "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidade do membro"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento"])},
          "IDENTIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificação"])},
          "MAIN_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade principal"])},
          "MEMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membros"])},
          "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa(s) Física(s)"])},
          "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa(s) Jurídica(s)"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posição"])},
          "BIRTH_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome de nascimento"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome(s)"])},
          "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma jurídica"])},
          "COMPANY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição da empresa"])},
          "ESTABLISHMENT_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do estabelecimento"])}
        },
        "TAX_REPORT": {
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações da empresa"])},
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
          "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de encerramento do exercício"])},
          "OPENING_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de abertura do exercício"])},
          "CONFIDENTIALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento confidencial"])},
          "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duração do período fiscal"])},
          "RATIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rácios financeiros"])},
          "MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " mês"]), _normalize([_interpolate(_named("n")), " meses"])])}
        },
        "TAX_NOTICE": {
          "TAX_ADRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço fiscal"])},
          "BANKID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificação bancária"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de conta"])},
          "REMAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo remanescente"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome de nascimento"])},
          "NUMBER_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de partes"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situação familiar"])},
          "CHECKS": {
            "FOUND_2D_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-DOC identificado"])},
            "NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência de nomes"])},
            "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência da data de estabelecimento"])},
            "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência da renda tributável de referência"])},
            "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência do número fiscal"])},
            "REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência da referência"])},
            "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência do ano de referência"])},
            "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência do número de partes"])},
            "NUMBER_OF_REGISTRANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistência do número de declarantes"])}
          }
        },
        "AUTHENTICITY": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidade"])},
          "DETAILED_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da análise"])},
          "NO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma alerta gerada pela análise do documento."])},
          "BINARY_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento modificado desde a criação"])},
          "COHERENCE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O documento não tem coerência em termos dos dados que recolhemos."])},
          "AUTOGENERATED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este documento foi gerado por um software de criação de documentos falsos"])},
          "METADATA_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise dos metadados revela uma modificação no documento"])},
          "PIXEL_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte da imagem foi modificada"])},
          "SOFTWARE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O último software utilizado é um software de edição de fotos (como o Photoshop), que pode ser usado para redimensionar (para que pareça autêntico) ou modificar a imagem."])},
          "REPORT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatório detalhado Finovox (PDF)"])},
          "ANALYSIS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As análises de autenticidade podem levar vários minutos."])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro durante o processo de OCR. Por favor, verifique a extensão do arquivo (pdf, jpg, jpeg, png permitidos) antes de tentar novamente. Se o problema persistir, entre em contato com o suporte."])}
        },
        "PROPERTY_TAX": {
          "PAYMENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de pagamento"])},
          "LEGAL_DEBTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devedores legais"])},
          "PROPERTIES_AND_BUILDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriedades e edifícios"])},
          "PAYMENT_DEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data limite de pagamento"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante remanescente a pagar"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificação bancária"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do credor"])},
          "TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante do imposto"])},
          "IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
          "RIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direito"])},
          "DESIGNATION_AND_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designação e endereço"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de pagamento"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante remanescente a reembolsar"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre o aviso de imposto"])},
          "ADDRESSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereços"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrante"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número fiscal"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência do aviso"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de função"])},
          "TAX_DEPARTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departamento fiscal"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do proprietário"])},
          "TAX_COMMUNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comuna fiscal"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do serviço"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de emissão"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de avaliação"])}
        },
        "INCOME_TAX": {
          "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre o aviso de imposto"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissão"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
          "FIP_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número FIP"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do serviço"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação fiscal"])},
          "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renda fiscal de referência"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarante"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de papel"])},
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço fiscal"])},
          "PAYMENT_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de pagamento"])},
          "FAMILY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da família"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor remanescente a pagar"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome de nascimento"])},
          "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de partes"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situação familiar"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre a notificação de imposto"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de pagamento"])},
          "MANDATE_UNIQUE_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência Única de Mandato"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante restante a reembolsar"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do credor"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificação bancária"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência do aviso"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do proprietário"])},
          "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano fiscal de referência"])}
        },
        "BANK_DETAILS": {
          "BANK_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código do banco"])},
          "BRANCH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código da agência"])},
          "ACCOUNT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da conta"])},
          "KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave RIB"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
          "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do banco"])},
          "BANK_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço do banco"])},
          "IBAN_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade do IBAN"])},
          "ACCOUNT_NUMBER_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validade do número da conta"])},
          "DOCUMENT_ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento analisado"])},
          "DIRECT_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débito direto"])},
          "CHECKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificações"])}
        },
        "PAYSLIP": {
          "PAYSLIP_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do Contracheque"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de Pagamento"])},
          "HIRE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Contratação"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "NET_PAYABLE_BEFORE_INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor Líquido Antes do Imposto de Renda"])},
          "NET_PAYABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor Líquido Após o Imposto de Renda"])},
          "PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de Pagamento"])}
        },
        "FACE_MATCHING": {
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação biométrica em andamento."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma vez que a análise for concluída pelo cliente, os controles abaixo serão exibidos. Por favor, aguarde."])},
          "FAILED_OR_CANCELLED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação biométrica não disponível."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro técnico durante a verificação biométrica."])},
          "CANCELLED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O usuário cancelou a verificação biométrica."])}
        }
      }
    },
    "NO_PERSON_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma pessoa encontrada"])},
    "ERROR_MESSAGE_API": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um problema ocorreu"])}
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setor"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão"])},
    "REQUEST_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da Solicitação"])},
    "HELPER_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendamos a realização de verificações adicionais."])},
    "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pontuação local"])},
    "INTERNATIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação internacional"])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise Financeira da Empresa"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarações Fiscais"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duração do Exercício"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Encerramento"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturamento"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucro Líquido"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacidade de Autofinanciamento"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margem Líquida"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estrutura Financeira"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidez Financeira"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessidade de Capital de Giro"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital de Giro"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo Financeiro"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dívidas de Curto Prazo / Balanço"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dívidas de Médio e Longo Prazo / Balanço"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração fiscal não disponível. Você pode fazer o upload de uma para obter sua análise."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanças"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponível"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indisponível"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não disponível"])},
  "ENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entidade"])},
  "ENTITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entidades"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mês"])},
  "OF_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do mês"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiário Efetivo"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empregado"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuações atualizadas em"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existência"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação da existência das informações com o requerente"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associação"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação da associação das informações com o requerente"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encerrar o Processo"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseja recuperar o resumo em PDF antes de encerrar a página?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diretor Geral"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail associado"])},
    "PHONE_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone associado"])},
    "ADDRESS_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço associado"])},
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associado ao e-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associado ao telefone"])},
    "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes Sociais"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idade"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gênero"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuso Horário"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operadora Atual"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operadora Original"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geolocalização"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietário"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])}
  },
  "HISTORIC_PAGE": {
    "HISTORIC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de Processos"])},
    "B2B_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise Empresarial"])},
    "B2C_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Particular"])},
    "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
    "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 7 dias"])},
    "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por página"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasta"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "MARKET_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Mercado"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da Jornada"])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "NUMBER_OF_TAX_NOTICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Avisos Fiscais"])},
    "NUMBER_OF_TAX_REPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Declarações Fiscais"])},
    "NUMBER_OF_ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de documentos de identificação"])},
    "NUMBER_OF_PASSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Passaportes"])},
    "NUMBER_OF_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Kbis"])},
    "NUMBER_OF_BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de RIB"])},
    "NUMBER_OF_DRIVING_LICENSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Carteiras de Motorista"])},
    "NUMBER_OF_RESIDENCE_PERMITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Títulos de Residência"])},
    "NUMBER_OF_PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contracheques"])},
    "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
    "MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de E-mail"])},
    "POSTAL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço Postal"])},
    "TELEPHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Fraude"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação Empresarial"])},
    "TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "STATUS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado da Pasta"])},
    "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhe"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da Empresa"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
    "OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operadora"])},
    "SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síntese"])},
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
    "FILE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do processo"])},
    "DATE_AND_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e hora"])},
    "DOCUMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de documento"])},
    "DOCUMENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do documento"])}
  },
  "REGEX": {
    "NOT_NULL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não pode ficar em branco."])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número não é válido."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O e-mail não é válido."])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O formato deve ser DD/MM/AAAA."])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve conter 9 dígitos."])},
    "NATIONAL_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve conter 10 dígitos."])},
    "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inválida."])},
    "LETTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve conter letras."])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve estar no formato IBAN."])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve conter apenas letras"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O link não é válido."])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve conter apenas números"])},
    "UPPER_CASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve conter apenas letras maiúsculas, números e underscores."])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Só pode conter letras, números e os símbolos _+%-"])}
  },
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])},
  "FIRST_SEEN_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastros de uso na web"])},
  "NUMBER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Identificação da Empresa"])},
  "EMPLOYER_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação do empregador"])},
  "PROCESS_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo de Lançamento do Open Banking"])},
  "INFORMATION_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações da Empresa"])},
  "SCORING_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Confiança da Empresa"])},
  "EMAIL_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domínio de E-mail"])},
  "EMAIL_DOMAIN_CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Criação do Domínio de E-mail"])},
  "EMAIL_DOMAIN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associação com o Domínio de E-mail"])},
  "EMAIL_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associação com o E-mail"])},
  "OTHER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posse de Outra Empresa"])},
  "IS_RECENTLY_ACQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adquirida Recentemente"])},
  "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuações Financeiras"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "EMAIL_CORRELATION_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência entre o domínio de e-mail e o site da web"])},
  "CHECK_IBAN": {
    "CONTAINER": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de IBAN"])},
      "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da Empresa"])}
    },
    "RESULT": {
      "OK": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O IBAN existe"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo de titular está correto"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de registro corresponde ao IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento corresponde ao IBAN"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome corresponde"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão bancária bem-sucedida"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN válido"])},
        "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX do remetente encontrado"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa conhecida"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa conhecida"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência entre o nome e o IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data válida"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco elegível para SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa registrada na plataforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise do histórico de pagamentos liga a empresa ou pessoa aos dados bancários"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise do histórico de pagamentos da comunidade liga a empresa ou pessoa aos dados bancários"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os dados bancários pertencem a uma empresa de factoring"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconhecido"])}
      },
      "KO": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O IBAN não existe"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo de titular está incorreto"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de registro não corresponde ao IBAN"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento não corresponde ao IBAN"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome não corresponde"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão bancária falhou"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN inválido"])},
        "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX do remetente não encontrado"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa desconhecida"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa desconhecida"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem correspondência entre o nome e o IBAN"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inválida"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco inelegível para SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa não registrada na plataforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise do histórico de pagamentos não conseguiu confirmar os dados bancários por serem desconhecidos"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise do histórico de pagamentos da comunidade não conseguiu confirmar os dados bancários por serem desconhecidos"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os dados bancários provavelmente pertencem a uma empresa de factoring ou são usados para esse fim"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconhecido"])}
      },
      "WARNING": {
        "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação do IBAN impossível"])},
        "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação do tipo de titular impossível"])},
        "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de correspondência entre número de registro e IBAN impossível"])},
        "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação da data de nascimento impossível"])},
        "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome coincide"])},
        "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão bancária falhou"])},
        "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN desativado (exemplo: conta encerrada)"])},
        "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX do remetente não disponível"])},
        "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa não encontrada"])},
        "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa não encontrada"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência entre nome e IBAN não verificada"])},
        "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data não verificada"])},
        "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco não elegível para SEPAMAIL"])},
        "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa não registrada na plataforma"])},
        "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do histórico de pagamentos muito fraca para confirmar uma ligação"])},
        "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do histórico de pagamentos comunitário muito fraca para confirmar uma ligação"])},
        "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os dados bancários podem pertencer a uma empresa de factoring ou ser usados para esse fim"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconhecido"])}
      },
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de IBAN"])},
      "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificações"])},
      "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão com o Banco"])},
      "typeTitulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Titular"])},
      "dateNaissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Nascimento"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "autreNom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro Nome"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A solicitação de verificação não pôde ser concluída devido a uma falha técnica"])},
      "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O IBAN existe"])},
      "IBAN_NOT_EXISTS_ANYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O IBAN não existe mais e a conta foi fechada há menos de 13 meses"])},
      "IBAN_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O IBAN não existe ou a conta foi fechada há mais de 13 meses"])},
      "IBAN_CAN_BE_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O IBAN não pode ser verificado: O usuário solicitou que sua conta não seja verificada com DIAMOND ou a conta está em moeda estrangeira ou não é compatível com operações de débitos diretos e transferências, e está fora de escopo, ..."])},
      "HOLDER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo de titular informado está correto"])},
      "HOLDER_TYPE_INCORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo de titular informado está incorreto"])},
      "SIREN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de identificação informado corresponde ao IBAN verificado"])},
      "SIREN_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de identificação informado não corresponde ao IBAN verificado"])},
      "SIREN_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O banco não pode verificar o número de identificação (por exemplo, porque não o conhece)"])},
      "BIRTHDATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento informada corresponde ao IBAN verificado"])},
      "BIRTHDATE_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento informada não corresponde ao IBAN verificado"])},
      "BIRTHDATE_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O banco não pode verificar a data de nascimento informada (por exemplo, porque não a conhece)"])},
      "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo de proprietário está correto"])},
      "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de registro corresponde ao IBAN"])},
      "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data de nascimento corresponde ao IBAN"])},
      "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome corresponde"])},
      "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN inválido"])},
      "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX do ordenante"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inválida"])},
      "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco elegível para SEPAMAIL"])},
      "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro inscrito"])},
      "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do histórico de pagamentos"])},
      "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do histórico de pagamentos comunitários"])},
      "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factoring"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconhecido"])},
      "REASON_CODE": {
        "IncorrectAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN inválido"])},
        "UnrecognisedInitiatingParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX do Ordenante"])},
        "InvalidIdentificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
        "MissingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "InvalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados inválidos"])},
        "BICInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco não elegível para verificação SEPAMAIL"])}
      }
    },
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que o banco consultado está enfrentando um problema de conexão com o SepaMail. Obrigado pela sua compreensão."])},
    "MEELO_RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendação Meelo"])},
    "RECOMMENDATION": {
      "DECISION": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conforme"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuidado"])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta"])}
      },
      "MESSAGE": {
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise do IBAN não contém alertas."])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise do IBAN contém alguns alertas."])},
        "risky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise do IBAN contém pelo menos um alerta grave."])}
      }
    }
  },
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações"])},
  "SYNTHESIS": {
    "FOLDER_STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado da Pasta"])},
    "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analista"])},
    "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associado"])},
    "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Único"])},
    "PHONE_AND_SOCIAL_NETWORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone e Redes Sociais"])},
    "LINKED_TO_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associado ao Endereço de E-mail"])},
    "LINKED_TO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associado ao Telefone"])},
    "BUSINESS_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
    "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação"])}
  },
  "WARNING_LIST": {
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da Empresa"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma alerta detectada durante nossa análise"])},
    "MESSAGE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenção, o(s) seguinte(s) parâmetro(s) foi/foram identificado(s) em um caso de fraude anterior"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Aviso Meelo"])},
    "MESSAGE_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenção, o parâmetro inserido foi identificado em um caso de fraude anterior."])},
    "MESSAGE_NO_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma alerta detectada durante nossa análise."])}
  },
  "SELECT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione as análises que deseja iniciar"])},
  "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
  "PHONE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, selecione um indicador e insira um número sem espaços ou caracteres entre os números."])},
  "BIRTHDATE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altamente recomendado para exibir o ecossistema do cliente."])},
  "EXAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "DOMAIN_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do Domínio"])},
  "DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domínio"])},
  "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarante"])},
  "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrador"])},
  "ADDITIONAL_CONTROLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controles Adicionais"])},
  "COMPANY_DOMICILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa Domiciliada"])},
  "EMAIL_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Criação do Domínio de E-mail"])},
  "WEBSITE_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Criação do Domínio do Site"])},
  "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de Inatividade Superior a 3 Anos"])},
  "LOCATION_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localização em Risco"])},
  "OPERATOR_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operadora em Risco"])},
  "INITIAL_RISKY_OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador inicial de risco"])},
  "NO_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para mais informações, por favor, inicie as pontuações"])},
  "HAS_OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras Empresas em Processos Coletivos"])},
  "HAS_OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras Empresas Radiadas"])},
  "HAS_OTHER_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outra empresa"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "SUPPORT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas? Não hesite em nos contatar"])},
  "SIDEBAR": {
    "KIT_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Particulares"])},
    "KIT_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Empresas"])},
    "KIT_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking"])},
    "KIT_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Documentos"])},
    "HISTORICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha Conta"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lote"])},
    "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuda"])},
    "KIT_RECOVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperação"])},
    "MEELO_KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Kit"])},
    "BACKOFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back office"])},
    "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])},
    "MEELO_ACADEMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academia Meelo"])},
    "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de documento(s)"])},
    "USERS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus usuários"])},
      "PROFILE_AND_ACCESS_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de perfis e acesso"])},
      "USER_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de usuários"])},
      "GROUP_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de grupos"])},
      "LOCATION_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de locais"])}
    },
    "LIST_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão de listas"])},
    "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página inicial"])},
    "MONITORING": {
      "MY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu consumo"])},
      "COMPANY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo da empresa"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoramento de consumo"])}
    },
    "RULES_ENGINE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor de regras"])},
      "COMPANY_RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras da empresa"])},
      "SETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conjuntos de regras"])}
    },
    "COMPANY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha empresa"])},
      "MANAGE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de listas"])}
    }
  },
  "BREADCRUMBS": {
    "COMPANY_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações da empresa"])},
    "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requerente"])},
    "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisão"])},
    "JUSTIFICATORIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprovantes"])},
    "SCORE_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleção de pontuação"])},
    "RECEIVER_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleção do receptor"])},
    "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "BATCH_RUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execução em lote"])}
  },
  "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeto"])},
  "PRODUCT": {
    "VEHICLES_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor total dos veículos com IVA"])},
    "CONTRIBUTION_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor da contribuição"])}
  },
  "CGI": {
    "DECISION": {
      "SUCCESS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última etapa!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossiga para a coleta de comprovantes"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar e Continuar"])}
      },
      "FAILED": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise está concluída!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parecer desfavorável"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluir"])}
      }
    },
    "DOCUMENTS": {
      "BLOCK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar documentos de comprovação"])}
      },
      "ID_CARD": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de identificação"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frente e verso do seu documento de identificação"])}
      },
      "BANK_DETAILS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrato bancário"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrato bancário da sua conta principal"])}
      },
      "KBIS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado de Registro Empresarial"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado de Registro Empresarial emitido nos últimos 3 meses"])}
      },
      "TAX_REPORT": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração de Imposto"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última declaração de imposto com pelo menos 2 anos de histórico"])}
      }
    },
    "CONGRATULATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parabéns"])},
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você terminou de preencher o dossiê no portal Meelo."])}
  },
  "TITLE_ACTION_DOCUMENT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de aquisição"])},
  "ONLY_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas PDF"])},
  "MULTI_REQUESTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Múltiplos Pedidos"])},
    "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos últimos 30 dias, um ou mais processos foram criados por"])},
    "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["com informações semelhantes e/ou idênticas."])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum processo semelhante ou idêntico foi inserido nos últimos 30 dias."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registros com o mesmo e-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registros com o mesmo número de telefone"])},
    "REGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registros com o mesmo número de registro"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de arquivos com o mesmo IBAN"])}
  },
  "RECOVERY": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do Cliente"])},
    "REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido"])},
    "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise financeira"])},
    "ANALYSIS_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de realização da análise"])},
    "BANK_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações bancárias"])},
    "CUSTOMER_RELIABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confiabilidade do cliente"])},
    "REJECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeição"])},
    "ON_AVERAGE_PER_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em média por mês"])},
    "INTERVENTION_FEES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de intervenção"])},
    "THIRD_PARTY_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso a terceiros"])},
    "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogos de azar"])},
    "FINANCIAL_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situação financeira"])},
    "AVERAGE_DISPOSABLE_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renda disponível média"])},
    "LOWEST_DISPOSABLE_INCOME_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento disponível mais baixo no período"])},
    "LOWEST_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento mais baixo"])},
    "LACK_OF_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausência de renda"])},
    "AVERAGE_MONTHLY_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renda mensal média"])},
    "FINANCIAL_PRESSURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressão financeira"])},
    "CREDITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créditos"])},
    "DEBT_COLLECTION_AGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresas de cobrança"])},
    "RISK_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nível de risco"])},
    "LOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixo"])},
    "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderado"])},
    "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arriscado"])},
    "LACK_OF_INCOME_SOURCES_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma ou várias fontes de renda foram detectadas"])},
    "LACK_OF_INCOME_SOURCES_NOT_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum rendimento detetado"])}
  },
  "BATCH": {
    "CLICK_ON_SCORES_BELOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique em um ou mais pontuação(ões) abaixo para escolher as pontuações a serem executadas via lote."])},
    "MANUAL_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruções"])},
    "MANUAL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para um uso ótimo do lote, recomendamos que você baixe o modelo fornecido acima. Ele será composto pela estrutura ideal adaptada para todas as pontuações selecionadas anteriormente. Você pode então preencher o arquivo do Excel com seus dados e enviá-lo um pouco mais abaixo na página."])},
    "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelamento do Lote bem-sucedido"])},
    "DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar o resultado do lote"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início do Lote em andamento..."])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você acabou de receber um e-mail com o resultado do lote"])},
    "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar o lote"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar o lote"])},
    "USAGE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de uso"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
    "DOWNLOAD_TEMPLATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar o modelo"])},
    "LIMIT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linhas / arquivo"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do arquivo de saída desejado"])},
    "SCORE": {
      "REQUEST_ID": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Fraude de Identidade"])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Fraude de Identidade Profissional"])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Confiança da Empresa"])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Avaliação de Risco do Setor de Energia"])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação E-Reputação"])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Risco"])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Altares"])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação Ellisphere"])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação CréditSafe"])}
      },
      "DESCRIPTION": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Pontuação de Fraude de Identidade é um modelo que avalia o risco de fraude de identidade de um cliente. É baseado na avaliação de vários critérios: nome, sobrenome, e-mail, telefone, mas também o endereço postal ou a data de nascimento."])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Pontuação de Fraude de Identidade profissional é um modelo que avalia o risco de fraude de identidade de um cliente empresarial (no contexto de uma relação B2B). É baseado na avaliação de vários critérios: nome, sobrenome, e-mail, telefone, mas também o endereço postal ou a data de nascimento."])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O serviço de pontuação 'Score Confiance Entreprise' é uma ferramenta para avaliar a empresa e a relação entre o solicitante (pessoa física) e a empresa (pessoa jurídica)."])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 'Pontuação de Risco Energético' é um modelo projetado especificamente para o setor de energia que avalia o risco de inadimplência do cliente. É baseado na avaliação de vários critérios: o operador de telecomunicações / o banco / os dados IRIS sobre o endereço postal / o valor mensal (exato ou estimado)."])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Score E-Reputation é um modelo projetado para avaliar a satisfação e a confiança dos consumidores em relação à empresa com base nas diferentes avaliações que deixaram."])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Score de Risco é um modelo projetado para avaliar a solvência de uma pessoa com base em suas informações pessoais e de pagamento."])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Score Altares é um modelo projetado para avaliar o risco de solvência de uma empresa com base nos resultados fornecidos pela empresa de classificações financeiras Altares."])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Score Ellisphere é um modelo projetado para avaliar o risco de solvência de uma empresa com base nos resultados fornecidos pela empresa de classificações financeiras Ellisphere."])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Score CréditSafe é um modelo projetado para avaliar o risco de solvência de uma empresa com base nos resultados fornecidos pela empresa de classificações financeiras CréditSafe."])}
      }
    }
  },
  "FILE": {
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher um arquivo"])}
  },
  "IMPORTANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante"])},
  "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arriscado"])},
  "BACKOFFICE": {
    "GROUPS": {
      "COMPANY_GROUPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão de grupos"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar grupo"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar grupo"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este grupo é utilizado por usuários, tem certeza de que deseja excluí-lo?"])}
    },
    "PLACES": {
      "COMPANY_PLACES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão de lugares"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar lugar"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar lugar"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este lugar é utilizado por usuários, tem certeza de que deseja excluí-lo?"])}
    },
    "USERS": {
      "SHORTCUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os atalhos"])},
      "COMPANY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários da empresa"])},
      "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
      "PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
      "USER_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificação de usuário"])},
      "PROFILE_ASSIGNMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições de Perfil"])},
      "USER_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusão de usuário"])},
      "DELETE": {
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a excluir o usuário"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da empresa"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O usuário não poderá mais acessar sua conta."])},
        "ACTION_IRREVERSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta ação é irreversível."])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja excluir este usuário?"])}
      },
      "USER_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil do usuário"])},
      "TECHNICAL_ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações técnicas"])},
      "SUPERVISOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
      "USER_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adição de usuário"])},
      "ACTIVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta ativa"])},
      "UPDATE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O status da conta foi modificado com sucesso."])},
      "RESET_PASSWORD": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinindo uma senha"])},
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a enviar um e-mail de redefinição de senha para o usuário"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para o endereço de e-mail"])},
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de redefinição de senha enviado"])}
      },
      "RESET_API_KEY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinindo uma chave de API"])},
        "CONFIRM_RESET_USER": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a enviar um e-mail de redefinição de chave de API para o usuário"])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para o endereço de e-mail"])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante : O link para recuperar a chave API é válido apenas por 30 minutos."])}
        },
        "CONFIRM_RESET_US": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a redefinir sua chave de API."])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por motivos de segurança, o valor da sua nova chave só pode ser recuperado uma vez."])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta nova chave será copiada diretamente para sua área de transferência digital."])},
          "FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aconselhamos colá-lo imediatamente em local seguro para poder reutilizá-lo posteriormente."])}
        },
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de redefinição de chave de API enviado"])},
        "NEW_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua nova chave de API foi copiada para sua área de transferência"])}
      }
    },
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Função"])},
    "ADVANCED_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário avançado"])},
    "ADVANCED_USER_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode criar outros usuários avançados e/ou básicos e acessar determinadas configurações."])},
    "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissões"])},
    "PROFILE_AND_ACCESS": {
      "COMPANY_PROFILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfis da empresa"])},
      "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissões"])},
      "MESSAGE_PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, selecione um perfil acima para ver a lista de permissões associadas."])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado por"])},
      "KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit"])},
      "MODULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módulo"])},
      "RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direitos"])},
      "ADDING_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adição de um perfil"])},
      "PROFILE_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificação de perfil"])},
      "SAVE_PERMISSION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foram detectadas alterações, não se esqueça de salvá-las usando o botão acima."])},
      "DESCRIPTION_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do perfil"])},
      "NO_PROFILE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum perfil encontrado"])}
    },
    "MONITORING": {
      "DOWNLOAD_BALANCE_SHEET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar o balanço"])},
      "TOTAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo total"])},
      "MONTHLY_EVOLUTION_CURVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curva de evolução mensal"])},
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
      "THIS_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta semana"])},
      "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana passada"])},
      "THIS_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mês"])},
      "LAST_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês passado"])},
      "GENERAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo geral"])},
      "COMPANY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo para a empresa"])},
      "DATA_ON_THE_WAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seus dados estão a caminho..."])},
      "CONSUMPTION": {
        "OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise documental de identidade"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise documental"])},
        "CHECK_VALUE_COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de IBAN B2B"])},
        "CHECK_VALUE_PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de IBAN B2C"])},
        "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise da empresa"])},
        "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise LCB-FT"])},
        "GET_LINKEDIN_PROFILE_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enriquecimento LinkedIn"])},
        "OPENBANKING_MEELO_OPENBANKING_PERSONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2C"])},
        "OPENBANKING_MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2B"])},
        "SCORING_ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação Ellisphère"])},
        "SCORING_CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação CréditSafe"])},
        "SCORING_WEB_SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação Confiança Empresarial"])},
        "SCORING_WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação Identidade Particular"])},
        "SCORING_E_REPUTATION_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação E-Reputação"])},
        "SCORING_ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação Altares"])},
        "SCORING_SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação Identidade Profissional"])},
        "SELECTED_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo de usuários selecionados"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo da empresa"])},
        "OF_MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo da minha equipe"])},
        "MY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu consumo"])}
      },
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foram encontrados dados. Efectue uma primeira análise para poder consultar o seu consumo."])}
    },
    "RULES_ENGINE": {
      "RULES": {
        "FOR_API_IMPLEMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para implementação da API"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqui você encontrará a lista de regras aprovadas para sua empresa."])},
        "RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "CONTAINED_IN_A_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contido em um conjunto"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
        "GENERIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genérico"])},
        "SPECIFIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Específico"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
        "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação"])},
        "MODIFY_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar regra"])},
        "RULE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da regra"])},
        "RULE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição da regra"])},
        "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
        "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor padrão"])},
        "PARAMETERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros"])},
        "ARGUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumentos"])},
        "EDIT_PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar parâmetro"])},
        "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do parâmetro"])},
        "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição do parâmetro"])},
        "FLOAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimal"])},
        "INTEGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inteiro"])},
        "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
        "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booleano"])},
        "LIST_STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de textos"])},
        "RULE_PRESENT_IN_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regra presente nos seguintes conjuntos"])},
        "RULE_V1_NOT_EDITABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta regra é uma regra V1 e não pode ser editada. Se desejar modificá-la, entre em contato com o Meelo."])}
      },
      "SETS": {
        "SETS_RULES_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para visualizar as regras de um conjunto, selecione a linha correspondente na área da tabela à esquerda."])},
        "NO_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há conjunto de regras para sua empresa. Entre em contato com o Meelo para criar um."])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "SETS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de conjuntos"])},
        "SETS_RULES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de regras do conjunto"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqui você encontrará a lista de conjuntos de regras criados para sua empresa."])},
        "ADD_OR_DELETE_RULE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para quaisquer alterações relacionadas à adição ou remoção de uma regra em um conjunto, entre em contato com o Meelo."])}
      }
    },
    "MY_COMPANY": {
      "USEFUL_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos úteis"])},
      "KITS_AND_MODULES_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kits e módulos disponíveis"])},
      "ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
      "SUPPORT_MEELO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoio ao Meelo"])}
    }
  },
  "RESEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisa"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página"])},
  "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sobre"])},
  "RESULTS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados encontrados"])},
  "NUMBER_OF_ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de itens por página"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo"])},
  "DATA_CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactabilidade de dados"])},
  "AND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
  "UNREACHABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inacessível"])},
  "LOCALE": {
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemão"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglês"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanhol"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francês"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holandês"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Português"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bélgica"])}
  },
  "ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliação"])},
  "E_REPUTATION": {
    "AVERAGE_GRADE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média não encontrada"])},
    "LIST_TEN_LAST_REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dos 10 últimos comentários"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de E-Reputação do Meelo"])},
    "NEGATIVE_REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação negativa"])},
    "REVIEW_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário da avaliação"])}
  },
  "NO_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem comentários"])},
  "PRINT": {
    "FOLDER_INFO": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações da Pasta"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Criação"])},
      "FOLDER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado da Pasta"])},
      "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analista"])},
      "UNIQ_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Único"])}
    },
    "APPLICANT_OB": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requerente"])},
      "OB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise do OpenBanking"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Nascimento"])},
      "BIRTH_PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de Nascimento"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de Email"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone Móvel"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço Postal"])},
      "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco"])},
      "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular"])},
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da Conta"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta Principal"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salário Mensal Médio"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluguel Mensal Médio"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporção de Despesas sobre Receitas"])},
      "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo Mensal"])},
      "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo Atual"])},
      "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisão Bancária"])},
      "TOP_DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detecção de Débito Diferido"])},
      "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Transações"])}
    },
    "DOCUMENT_ANALYSIS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Documentos"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformidade"])},
      "DOCUMENT_CONTROL": {
        "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de Identificação"])},
        "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de Identificação"])},
        "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta de Condução"])},
        "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte"])},
        "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissão de Residência"])},
        "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS - Empresa ou Situação"])},
        "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Aviso de Situação"])},
        "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrato de KBIS"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidade - Análise de não alteração digital"])},
        "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração de Imposto"])},
        "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração de Imposto Simplificada"])},
        "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração de Imposto Complexa"])},
        "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de Imposto - Renda ou Propriedade"])},
        "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto de Renda"])},
        "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto sobre Propriedade"])},
        "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes Bancários"])},
        "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holerite"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconhecido"])},
        "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle biométrico"])}
      }
    },
    "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes do Score"])},
    "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de IBAN"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combate à Lavagem de Dinheiro e Financiamento do Terrorismo"])},
    "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise Detalhada do OpenBanking"])},
    "ANALYSIS_B2B": {
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
      "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações da empresa"])},
      "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações legais"])},
      "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do cliente"])},
      "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da empresa"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
      "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Força de trabalho"])},
      "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação"])},
      "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de registro de IVA"])},
      "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de atividade"])},
      "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma jurídica"])},
      "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital"])},
      "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
      "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo de atividade"])},
      "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de encerramento"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliação"])},
      "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrante"])},
      "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
      "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrador"])},
      "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acionistas e beneficiários conhecidos"])},
      "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento"])},
      "NATIONALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidade"])},
      "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posição"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação"])},
      "WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de alertas Meelo"])},
      "BUSINESS_REPRESENTS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerentes e representantes conhecidos"])},
      "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da pontuação"])},
      "COMPANY_SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de confiança da empresa"])},
      "COMPANY_FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanças da empresa"])},
      "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos legais da empresa"])},
      "ANNUAL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas anuais"])},
      "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estabelecimentos"])},
      "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolução da empresa"])},
      "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuações financeiras"])},
      "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputação"])},
      "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise detalhada do OpenBanking"])},
      "ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de IBAN"])},
      "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combate à Lavagem de Dinheiro e ao Terrorismo"])}
    },
    "COVER_PAGE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatório sobre os controles realizados"])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivo criado por"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivo criado em"])},
      "DOC_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise Empresarial"])},
      "DOC_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise Particular"])},
      "DOC_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise Open-Banking"])},
      "DOC_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Documento"])},
      "DOC_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Síntese de Documento"])}
    }
  },
  "ENRICHMENT": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para iniciar uma pesquisa de coordenadas, por favor cole abaixo o URL do perfil do LinkedIn do seu contato."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enriquecimento de dados"])},
    "NOPROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum perfil do LinkedIn encontrado."])},
    "NOCORDINATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma coordenada encontrada."])},
    "TITLE_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordenadas encontradas :"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail(s)"])},
    "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail(s) profissional(is) :"])},
    "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail(s) pessoal(is) :"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone(s):"])},
    "NO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum telefone encontrado"])},
    "NO_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum email encontrado"])},
    "MATCH": {
      "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência com o correio eletrónico pessoal introduzido"])},
      "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponde ao correio eletrónico profissional introduzido"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponde ao telefone introduzido"])}
    }
  },
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOVIDADE"])},
  "DECLARATION": {
    "FRAUD": {
      "BUTTON_SIDEBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["declarar uma fraude"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração de fraude"])},
      "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, preencha o formulário abaixo para casos envolvendo roubo de identidade ou fraude confirmada."])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Data de recebimento do pedido fraudulento"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Tipo de caso"])},
      "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Endereço de e-mail declarado"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Número de telefone declarado"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor estimado da fraude"])},
      "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL do caso"])},
      "AVOID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fraude foi evitada ?"])},
      "TOOLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com que ferramenta(s)"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quais elementos o levam a acreditar que é fraude ?"])},
      "CONDITION_FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * Aceito os "])},
      "CONDITION_SECOND_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termos gerais de uso"])},
      "CONDITION_THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" do Meelo relativos à declaração manual de fraude"])},
      "PROFESIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profissional "])},
      "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particular"])}
    }
  },
  "RECOMMENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação opcional, mas altamente recomendada"])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBRIGADO"])},
  "CONFIRM_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua declaração foi enviada com sucesso"])},
  "MODULE": {
    "ALTARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação Altares"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "BUSINESS_OWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietários de Empresas Efetivos"])},
    "CHECK_IBAN_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação de IBAN B2B"])},
    "COMPANY_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas de Empresa"])},
    "COMPANY_ANNUAL_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas Anuais da Empresa"])},
    "COMPANY_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estabelecimentos da Empresa"])},
    "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolução da Empresa"])},
    "COMPANY_FINANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanças da Empresa"])},
    "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações da Empresa"])},
    "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos Legais da Empresa"])},
    "COMPANY_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Confiança da Empresa"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de Crédito Seguro"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputação"])},
    "OB_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas de Open Banking"])},
    "LITIGATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situação jurídica - Contencioso"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julgado em "])},
      "DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demanda: "])},
      "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localização: "])},
      "MAIN_HEARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main hearing"])},
      "SUMMARY_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary procedure"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconhecido"])}
    },
    "FINANCIAL_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seção de análise de risco financeiro"])}
  },
  "CARTOGRAPHY": {
    "COMPANY_COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa em Processo Coletivo"])},
    "BUSINESS_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Empresarial"])},
    "CARTOGRAPHY_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartografia de"])},
    "STUDIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa analisada"])},
    "ACTIVE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa Ativa"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa em Processo Coletivo"])},
    "COMPANY_CARTOGRAPHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartografia da Empresa"])},
    "DEREGISTERED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa Desregistada"])},
    "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "COMPANY_ACTIVITIES_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de atividade da empresa"])},
    "COMPANY_ACTIVITIES_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da atividade da empresa"])},
    "RECENTLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recentemente"])},
    "UNVERIFIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa não verificada"])},
    "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prolongar"])}
  },
  "MENU": {
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha conta"])},
    "MY_CONSUPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu consumo"])},
    "PORTAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do portal"])},
    "LOG_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar como"])},
    "BACK_OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-office"])},
    "LOGOUT_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair da conta do cliente"])},
    "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])}
  },
  "CONNECT_AS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar como "])},
    "PARAGRAPH": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a entrar como"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da empresa"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portanto, você verá o portal de acordo com as permissões concedidas a este usuário."])},
      "FOURTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as suas ações serão rastreadas sob o nome do cliente e o seu."])},
      "FIFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor,"])},
      "SIXTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tenha cuidado"])},
      "SEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ao usar o portal como cliente, e"])},
      "EIGHTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respeite a confidencialidade"])},
      "NINTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dos dados de nossos clientes."])},
      "TENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não se esqueça de"])},
      "ELEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sair da conta do cliente"])},
      "TWELFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["após suas ações serem concluídas."])}
    },
    "TOAST": {
      "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não está mais conectado como"])},
      "LOG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora você está conectado como"])}
    },
    "BADGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conectado como"])},
    "MODAL": {
      "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
      "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])}
    }
  },
  "PORTAL_INFORMATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações no portal MEELO"])},
    "SUBTITLE": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O portal Meelo"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : Uma recomendação resumida"])}
    },
    "DESCRIPTION": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os produtos Meelo ao seu alcance para tomar a sua decisão em poucos minutos."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nosso portal de apoio à decisão permite-lhe utilizar os produtos Meelo necessários à devida verificação do cliente que entra em contacto consigo."])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação de fraude, verificação de identidade, Open Banking, verificação de documentos… Produtos Meelo ao seu alcance com integração técnica mínima."])}
    },
    "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versão do seu portal"])},
    "ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente"])},
    "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produção"])},
    "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenvolvimento"])},
    "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encenação"])},
    "GDPR": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])},
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuímos grande importância à proteção dos seus dados pessoais. De acordo com o Regulamento Geral de Proteção de Dados (RGPD), apenas recolhemos a informação necessária, obtemos o seu consentimento explícito, utilizamos os seus dados e os dos seus clientes de forma legítima, garantimos a sua segurança, limitamos a sua retenção e respeitamos os seus direitos."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se tiver alguma dúvida ou preocupação relativamente a esta política de privacidade, pode consultar o nosso"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ou entre em contato com nosso DPO, em "])}
    },
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["página dedicada"])}
  },
  "MAINTENANCE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os seguintes serviços podem registar problemas: "])},
    "FINISHED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não se esqueça de atualizar o seu portal para uma utilização optimizada."])},
    "ONGOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenção em curso"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenção terminada"])},
    "SERVICE": {
      "AML_CFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Combate à Lavagem de Dinheiro e ao Financiamento do Terrorismo"])},
      "CHECKOUT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Assinatura Eletrônica"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Envio de Documentos"])},
      "DOCUMENT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Controle de Documentos"])},
      "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Documentação Externa"])},
      "IAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Autenticação"])},
      "OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Open Banking"])},
      "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal Meelo"])},
      "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor de Regras"])},
      "SCORING_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Pontuação"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Gestão de Tarefas Internas"])},
      "TAX_NOTICE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Análise de Avisos Fiscais"])},
      "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Análise de Declarações Fiscais"])},
      "TRANSLATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço Interno de Tradução"])},
      "SHORTENER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço Interno de Encurtamento de URL"])},
      "CHECK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Enriquecimento Empresarial"])},
      "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço Interno de Envio de Documentos"])},
      "TRACKETIO_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Análise de Elementos de Rastreamento Meelo"])},
      "STORAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço Interno de Armazenamento de Documentos"])},
      "ALERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Alerta"])},
      "TRACKETIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Rastreamento de Usuários no Navegador"])},
      "OPEN_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Receita Aberta"])},
      "ENRICHMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Enriquecimento de Dados"])},
      "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Busca e Correspondência de Imagens"])},
      "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço de Verificação de IBAN"])}
    }
  },
  "FOLDER": {
    "WORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pasta"])},
    "STATE": {
      "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em andamento"])},
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceito"])},
      "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recusado"])},
      "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguardando"])},
      "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluído"])}
    },
    "DELETION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusão da pasta"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está prestes a excluir o"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e os dados que o constituem"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja excluir esta pasta?"])},
        "QUESTION_MOTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja excluir este motivo?"])}
      }
    },
    "EDITION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualização do estado do dossiê"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não poderá mais continuar inserindo dados neste dossiê"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja modificar o estado do dossiê?"])}
      },
      "STATE": {
        "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iniciar"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceitar"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recusar"])},
        "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esperar"])},
        "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelar"])},
        "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excluir"])}
      }
    },
    "UPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status do arquivo atualizado"])}
  },
  "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.º"])},
  "NOTIFICATIONS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem notificações."])},
    "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo "])},
    "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler "])},
    "MARK_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MARCAR TUDO COMO LIDO"])},
    "DELETE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXCLUIR TUDO"])},
    "DATE": {
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOJE"])},
      "YESTERDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONTÉM"])},
      "OLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTIGO"])}
    },
    "TIMESTAMP": {
      "NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora mesmo"])},
      "PREFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atrás"])},
      "SUFFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "MINUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuto"])},
      "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos"])},
      "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hora"])},
      "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horas"])}
    },
    "ACTIONS": {
      "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "SERVICES": {
      "OPEN_BANKING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise OpenBanking"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise OpenBanking - Falha na análise"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise OpenBanking da empresa "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["está completa."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["está completo. Clique na notificação para ver o resultado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A análise OpenBanking encontrou um problema. Clique na notificação para visualizar o ficheiro."])}
      },
      "LIVE_CHECK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolução de dúvidas - Análise concluída"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolução de dúvidas - Falha na análise"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A resolução de dúvidas do dossiê "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluída."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluída. Clique na notificação para consultar o resultado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houve um problema na resolução de dúvidas. Clique na notificação para consultar o dossiê."])}
      },
      "BATCH_CHECK_COMPANY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise empresarial - Lote concluído"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise empresarial - Falha no lote"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O lote de análise empresarial "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluído."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluído."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O lote de análise empresarial encontrou um problema."])}
      },
      "BATCH_CHECK_IBAN": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise IBAN - Lote concluído"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise IBAN - Falha no lote"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O lote de análise IBAN "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluído."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluído."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O lote de análise IBAN encontrou um problema."])}
      },
      "BATCH_SCORING_PROXY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Lote concluído"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Falha no lote"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O lote de análise de scoring "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluído."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluído."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O lote de análise de scoring encontrou um problema."])}
      },
      "FACE_MATCHING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação biométrica - Análise concluída"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação biométrica - Falha na análise"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verificação biométrica do arquivo "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluída."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foi concluída. Clique na notificação para ver o resultado."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verificação biométrica encontrou um problema. Clique na notificação para ver o arquivo."])}
      }
    }
  },
  "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "THE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "SCORE_CREDIT_SAFE": {
    "RISK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risco financeiro"])},
    "PAYMENT_TREND_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendência de pagamento"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum dado encontrado."])},
    "OTHER_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras informações adicionais"])},
    "PRESENCEOFJUDGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de julgamento"])},
    "PRESENCEOFPRIVILEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença de privilégio"])},
    "PRESENCEOFSANCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presença da sanção DGCCRF"])},
    "HEADBAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para saber mais sobre a saúde financeira desta empresa, ative sua assinatura CreditSafe"])},
    "RISK": {
      "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixo risco financeiro"])},
      "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risco financeiro moderado"])},
      "HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto risco financeiro"])},
      "NOT_SCORED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não verificável"])}
    },
    "PAYMENT_TREND": {
      "WORSENING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deterioração da tendência de pagamento"])},
      "IMPROVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendência de pagamento melhorando"])},
      "STABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendência de pagamento estável"])},
      "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendência de pagamento não disponível"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendência de pagamento não verificável"])}
    }
  },
  "MALICIOUS_PAYLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados de entrada inválidos"])},
  "RESET_API_KEY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua chave de API"])},
    "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode visualizar sua nova chave de API apenas uma vez"])},
    "CANNOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pode mais visualizar sua chave API, por favor entre em contato com seu responsável ou com o suporte da Meelo"])},
    "COPY_YOUR_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar sua chave"])}
  },
  "DQE_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência da linha telefónica - ID móvel"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro, a pesquisa não pôde ser concluída."])},
    "NO_SEARCH_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este número de telefone não pode ser verificado atualmente pela operadora."])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência"])},
    "KEY": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro nome"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço postal"])}
    },
    "LAUNCH_MATCHING_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar a pesquisa de correspondência"])},
    "NO_GLOBAL_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O controle foi feito, mas não pode ser concluído."])}
  },
  "MOTIVE": {
    "THIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão de motivos"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
    "TABLE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rótulo"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
      "NODATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum motivo existente."])}
    },
    "MODAL": {
      "CREATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um motivo"])},
      "UPDATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar um motivo"])},
      "DELETE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir um motivo"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rótulo"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "TOAST": {
      "SUCCESS": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão criado com sucesso"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão editado com sucesso"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão excluído com sucesso"])}
      },
      "FAILED": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao criar o padrão"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao editar o padrão"])}
      }
    }
  }
}